import { Flex, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { displayRelativeDate } from '../../utils';
import Breadcrumbs from '../Breadcrumbs';
import { ContentPageTitle } from '../Layout';
import DocumentationContext from '../../contexts/DocumentationContext';
import InventoryModelContext from '../../contexts/InventoryModel';

// Custom title lookup for static pages
export const CustomTitles: any = {
  audit_trail: 'Project Activity',
  getting_started: 'Getting Started',
  findings: 'Project Findings',
};

interface ISubsectionHeaderProps {
  pageId?: string;
  pageTitle?: string;
  renderAfterTitle?: () => JSX.Element | undefined;
  moreInfo?: JSX.Element;
}

function SubsectionHeaderForDocumentation({ pageId }: ISubsectionHeaderProps) {
  const { inventoryModel } = useContext(InventoryModelContext);
  const { latestEvent, setLatestEvent } = useContext(DocumentationContext);

  useEffect(() => {
    setLatestEvent(undefined);
  }, [pageId]);

  return (
    <Flex gap={2}>
      <Flex direction="column">
        {latestEvent && (
          <Text color={'neutral.500'} fontSize={'sm'}>
            {`Last updated ${
              inventoryModel && displayRelativeDate(latestEvent.created_at)
            } by ${latestEvent.context.actor.displayName}`}
          </Text>
        )}
      </Flex>
    </Flex>
  );
}

export function SubsectionHeader({
  pageId,
  pageTitle,
  renderAfterTitle,
  moreInfo,
}: ISubsectionHeaderProps) {
  const { templates } = useContext(InventoryModelContext);
  const currentPath = useLocation();
  // Check if path matches a documentation page path and
  // based on that show the user/created/updated components
  const isDocumentationPath = /\/model-inventory\/\w+\/documentation\/\w+/.test(
    currentPath.pathname,
  );

  let desiredPageTitle;
  // Check if the pageId is available in the template lookup dictionary
  if (pageTitle) {
    desiredPageTitle = pageTitle;
  } else if (pageId) {
    const templateLookup = templates.current.content.lookup;
    const page = (templateLookup && templateLookup[pageId]) || {
      index: '0',
      title: 'No Title',
    };
    const pageTitleWithIndex = page.index
      ? `${page.index}. ${page.title}`
      : page.title;
    desiredPageTitle = CustomTitles[pageId] || pageTitleWithIndex;
  }

  return (
    <>
      <Flex
        id="section-header"
        justify="space-between"
        w={'full'}
        pt={6}
        pb={4}
        maxWidth={'1220px'}
      >
        <VStack id="section-header-vstack" align="start" w={'full'}>
          <Breadcrumbs />
          <HStack>
            <ContentPageTitle mt={2}>
              {desiredPageTitle} {moreInfo && moreInfo}
            </ContentPageTitle>
            <Spacer />
            {!!renderAfterTitle && renderAfterTitle()}
          </HStack>
          {isDocumentationPath && (
            <SubsectionHeaderForDocumentation pageId={pageId} />
          )}
        </VStack>
      </Flex>
    </>
  );
}
