import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  VStack,
} from '@chakra-ui/react';
import MasterSearchBar from '../Layout/MasterSearchBar';
import ProjectHeader from '../Layout/ProjectHeader';
import { Link as RouterLink } from 'react-router-dom';
import ProjectStatusBar from '../ProjectStatusBar';
import { TInventoryModel } from '../../models/inventory_model';

export const getDocumentHeaderBreadcrumbs = (
  inventoryModel: TInventoryModel,
  currentLabel: string,
) => [
  { to: '/model-inventory', label: 'Model Inventory' },
  {
    to: `/model-inventory/${inventoryModel.cuid}/overview`,
    label: inventoryModel.name,
  },
  { to: '#', label: currentLabel, isCurrentPage: true },
];

interface DocumentHeaderProps {
  inventoryModel: TInventoryModel;
  breadcrumbLinks: Array<{
    to: string;
    label: string;
    isCurrentPage?: boolean;
  }>;
}

const DocumentHeader = ({
  inventoryModel,
  breadcrumbLinks,
}: DocumentHeaderProps) => {
  return (
    <VStack w={'full'}>
      <HStack w={'full'}>
        <MasterSearchBar />
      </HStack>
      <ProjectHeader>
        <Breadcrumb color="neutral.500">
          {breadcrumbLinks.map((link, index) => (
            <BreadcrumbItem key={index} isCurrentPage={link.isCurrentPage}>
              <BreadcrumbLink as={RouterLink} to={link.to}>
                {link.label}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
        {inventoryModel && <ProjectStatusBar inventoryModel={inventoryModel} />}
      </ProjectHeader>
    </VStack>
  );
};

export default DocumentHeader;
