import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import {
  Box,
  Flex,
  Stack,
  useColorModeValue,
  HStack,
  Icon,
  Button,
  useDisclosure,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Input,
  Select,
  MenuItem,
  IconButton,
  As,
  Heading,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  FormControl,
  Textarea,
  MenuDivider,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';
import './styles.css';
import {
  ChartBarSquareIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/react/24/outline';
import { ContentPageTitle, Label } from '../../components/Layout';
import MoreInfoPopOver from '../../components/MoreInfoPopOver';
import AddOrEditVisualizationModal from '../../components/AddOrEditVisualizationModal';
import { GridViewItem } from '../../components/GridView/GridViewItem';
import {
  TDashboard,
  TDashboardVisualization,
  TDashboardVisualizationJSON,
} from '../../models/report';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import API from '../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import RenderVisualization from '../../components/Reporting/Visualizations/RenderVisualization';
import { WidthProvider } from 'react-grid-layout';
import ReactGridLayout from 'react-grid-layout';
import { AddIcon } from '@chakra-ui/icons';
import { AddToEndOfLayout } from '../../layoutUtils';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

const COLS = 4;

type DashboardSelectorProps = {
  selectedDashboard: TDashboard | undefined;
  onDashboardChange: (dashboard: TDashboard) => void;
  allDashboards?: TDashboard[];
};

export const DashboardSelector = ({
  selectedDashboard,
  onDashboardChange,
  allDashboards,
}: DashboardSelectorProps) => {
  return (
    <HStack direction="row" spacing={4}>
      <Select
        value={selectedDashboard?.cuid}
        onChange={e => {
          const selected = allDashboards?.find(
            dashboard => dashboard.cuid === e.target.value,
          );
          if (selected) {
            onDashboardChange(selected);
          }
        }}
      >
        {allDashboards?.map(dashboard => (
          <option key={dashboard.cuid} value={dashboard.cuid}>
            {dashboard.name}
          </option>
        ))}
      </Select>
    </HStack>
  );
};

type AddOrEditDashboardModalProps = {
  isOpen: boolean;
  existingDashboard?: TDashboard;
  onClose: () => void;
  onSave: ({
    id,
    name,
    description,
  }: {
    id?: string;
    name: string;
    description: string;
  }) => Promise<void>;
};

export const AddOrEditDashboardModal = ({
  isOpen,
  existingDashboard,
  onClose,
  onSave,
}: AddOrEditDashboardModalProps) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    await onSave({ name, description, id: existingDashboard?.cuid });
    setIsSubmitting(false);
    onClose();
  };

  useEffect(() => {
    if (existingDashboard) {
      setName(existingDashboard.name);
      setDescription(existingDashboard.description || '');
    } else {
      setName('');
      setDescription('');
    }
  }, [existingDashboard]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {!!existingDashboard ? 'Edit' : 'Add New'} Page
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={4}>
            <FormControl>
              <Label mb={2}>Page Name</Label>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder=""
              />
            </FormControl>
            <FormControl>
              <Label mb={2}>Description</Label>
              <Textarea
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="What will this page be about..."
              ></Textarea>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Spacer />
          <Button
            isDisabled={!name}
            isLoading={isSubmitting}
            onClick={onSubmit}
            variant={'primary'}
          >
            {!!existingDashboard ? 'Save Changes' : 'Add New Page'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ViewVisualizationFullScreenModal = ({
  isOpen,
  onClose,
  visualizationJSON,
}: {
  isOpen: boolean;
  onClose: () => void;
  visualizationJSON?: TDashboardVisualizationJSON;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc
      closeOnOverlayClick
      scrollBehavior={'inside'}
      size={'full'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody display="flex">
          <VStack width="full" alignItems="flex-start">
            <Box display="flex" flexGrow={0} mb={4}>
              <Heading as="h2">{visualizationJSON?.title}</Heading>
            </Box>
            {!!visualizationJSON && (
              <>
                <Box display="flex" w="full" flex={1}>
                  <RenderVisualization
                    isEditing={false}
                    visualizationJSON={visualizationJSON}
                  />
                </Box>
              </>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

function FloatingIconButton({ onClick }: { onClick?: () => void }) {
  return (
    <Box position="fixed" bottom="20px" right="20px" zIndex="100">
      <IconButton
        icon={<AddIcon boxSize={6} />}
        variant={'primary'}
        aria-label="Add item"
        isRound
        shadow="lg"
        style={{
          height: '64px',
          width: '64px',
        }}
        onClick={onClick}
      />
    </Box>
  );
}

type TabProps = {
  isSelected: boolean;
  icon?: As;
  label: string;
  labelColor?: string;
  onClick?: () => void;
};

const Tab = ({ isSelected, icon, label, labelColor, onClick }: TabProps) => {
  return (
    <Box
      position={'relative'}
      top={'1px'}
      px={5}
      py={2}
      borderBottom={isSelected ? '2px solid' : 'none'}
      borderBottomColor={isSelected ? 'brand.500' : 'transparent'}
      color={isSelected ? 'brand.500' : labelColor || 'inherit'}
      cursor={'pointer'}
      onClick={onClick}
    >
      <HStack spacing={2}>
        {!!icon && <Icon as={icon} boxSize={3} />}
        <Box>{label}</Box>
      </HStack>
    </Box>
  );
};

const DashboardDeleteConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const cancelRef = useRef() as any;
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Page
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="red"
              onClick={onConfirm}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default function NewReports() {
  const addEditModal = useDisclosure();
  const deleteDashboardModal = useDisclosure();
  const createDashboardModal = useDisclosure();
  const viewVisualizationModal = useDisclosure();
  const { getAccessTokenSilently } = useAuth0();
  const [selectedDashboard, setSelectedDashboard] = useState<TDashboard>();
  const [dashboardToEdit, setDashboardToEdit] = useState<TDashboard>();
  const [visualizationToEdit, setVisualizationToEdit] = useState<
    TDashboardVisualization | undefined
  >();
  const [dashboards, setDashboards] = useState<TDashboard[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useQuery(
    'dashboards',
    async () => {
      const accessToken = await getAccessTokenSilently();
      const fetchedDashboards = await API.GetDashboards('report', accessToken);
      setDashboards(fetchedDashboards);

      // Set selected dashboard to the first one if none is selected
      if (fetchedDashboards.length > 0) {
        const dashboardCUID = searchParams.get('page');
        if (dashboardCUID) {
          const foundDashboard = fetchedDashboards.find(
            dashboard => dashboard.cuid === dashboardCUID,
          );
          if (foundDashboard) {
            setSelectedDashboard(foundDashboard);
            return;
          }
        }
        setSelectedDashboard(fetchedDashboards[0]);
      }
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const onSubmitVisualization = async (
    json: TDashboardVisualizationJSON,
    visualizationCuid?: string,
  ) => {
    const accessToken = await getAccessTokenSilently();
    const copyOfSelectedDashboard = { ...selectedDashboard! };

    // If the visualization is new, add it to the layout
    if (!visualizationCuid) {
      const layout = copyOfSelectedDashboard.layout;

      const visualization = await API.CreateDashboardVisualization(
        copyOfSelectedDashboard.cuid,
        json,
        accessToken,
      );

      await API.UpdateDashboard(
        copyOfSelectedDashboard.cuid,
        copyOfSelectedDashboard,
        accessToken,
      );

      AddToEndOfLayout(layout, {
        i: visualization.cuid,
        w: 2,
        h: 2,
      });

      copyOfSelectedDashboard.visualization_map[visualization.cuid] =
        visualization;
    } else {
      // If the visualization already exists, update the visualization
      const visualization = await API.UpdateDashboardVisualization(
        visualizationCuid,
        json,
        accessToken,
      );

      copyOfSelectedDashboard.visualization_map[visualization.cuid] =
        visualization;
    }
    setSelectedDashboard(copyOfSelectedDashboard);
    addEditModal.onClose();
  };

  const onDashboardSave = useCallback(
    async ({
      id,
      name,
      description,
    }: {
      id?: string;
      name: string;
      description: string;
    }) => {
      const accessToken = await getAccessTokenSilently();
      if (!id) {
        const newDashboard = await API.CreateDashboard(
          { name, description, dashboard_type: 'report' },
          accessToken,
        );
        setDashboards([...dashboards, newDashboard]);
        setSelectedDashboard(newDashboard);
      } else {
        const updatedDashboard = await API.UpdateDashboard(
          id,
          { name, description },
          accessToken,
        );
        const copyOfDashboards = [...dashboards];
        const index = copyOfDashboards.findIndex(d => d.cuid === id);
        copyOfDashboards[index] = updatedDashboard;
        setDashboards(copyOfDashboards);
        setSelectedDashboard(updatedDashboard);
      }
    },
    [dashboards, getAccessTokenSilently, setDashboards],
  );

  useEffect(() => {
    if (selectedDashboard) {
      setSearchParams({ page: selectedDashboard.cuid });
    }
  }, [selectedDashboard]);

  // This is needed for some reason to make the grid work
  // otherwise new items to the grid won't have the correct Width and Height
  const MeasuredGridView = useMemo(
    () => WidthProvider(ReactGridLayout),
    [selectedDashboard],
  );

  const canEdit = !!selectedDashboard && !selectedDashboard.is_default;

  const canAdd =
    !addEditModal.isOpen && !createDashboardModal.isOpen && canEdit;

  return (
    <>
      <DashboardDeleteConfirmModal
        isOpen={deleteDashboardModal.isOpen}
        onClose={deleteDashboardModal.onClose}
        onConfirm={async () => {
          const accessToken = await getAccessTokenSilently();
          const newDashboards = dashboards.filter(
            d => d.cuid !== selectedDashboard?.cuid,
          );
          await API.DeleteDashboard(selectedDashboard!.cuid, accessToken);
          setDashboards(newDashboards);
          setSelectedDashboard(newDashboards[0]);
          deleteDashboardModal.onClose();
        }}
      />
      <ViewVisualizationFullScreenModal
        isOpen={viewVisualizationModal.isOpen}
        onClose={viewVisualizationModal.onClose}
        visualizationJSON={visualizationToEdit?.json}
      />
      <AddOrEditVisualizationModal
        isOpen={addEditModal.isOpen}
        onClose={addEditModal.onClose}
        onSubmit={onSubmitVisualization}
        existingVisualizationId={visualizationToEdit?.cuid}
        existingVisualizationJSON={visualizationToEdit?.json}
      />
      <AddOrEditDashboardModal
        existingDashboard={dashboardToEdit}
        isOpen={createDashboardModal.isOpen}
        onClose={createDashboardModal.onClose}
        onSave={onDashboardSave}
      />
      {canAdd && (
        <FloatingIconButton
          onClick={() => {
            setVisualizationToEdit(undefined);
            addEditModal.onOpen();
          }}
        />
      )}
      <Box
        py={10}
        px={8}
        flex={1}
        w={'full'}
        overflow={'auto'}
        className="no-scrollbar"
        bg={useColorModeValue('white', 'neutral.950')}
      >
        <Stack>
          <HStack>
            <Flex width={'full'} justify={'space-between'}>
              <HStack gap={5} pl={2} color={'inherit'}>
                <Icon as={ChartBarSquareIcon} boxSize={10} />
                <ContentPageTitle>
                  Analytics
                  <MoreInfoPopOver
                    title="Analytics"
                    description="View insights into your model validation efforts, detailing critical findings, risk exposure, and compliance status."
                    link="https://docs.validmind.ai/guide/model-validation/view-reports.html"
                    placement="right-end"
                    iconProps={{
                      ml: 2,
                    }}
                  />
                </ContentPageTitle>
              </HStack>
            </Flex>
          </HStack>
          <HStack borderBottom="1px solid #E2E8F0">
            {dashboards?.map(dashboard => (
              <Tab
                key={`tab-${dashboard.cuid}`}
                isSelected={selectedDashboard?.cuid === dashboard.cuid}
                onClick={() => setSelectedDashboard(dashboard)}
                label={dashboard.name}
              />
            ))}
            <Tab
              key={`tab-add-dashboard`}
              isSelected={false}
              onClick={() => {
                setDashboardToEdit(undefined);
                createDashboardModal.onOpen();
              }}
              label="Add Page"
              icon={AddIcon}
            />
          </HStack>
          {selectedDashboard && (
            <>
              <VStack alignItems={'flex-start'} px={4} pt={4}>
                <HStack justifyContent={'space-between'} w={'100%'}>
                  <Heading as={'h2'}>{selectedDashboard.name}</Heading>
                  {canEdit && (
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Page options"
                        icon={<Icon as={EllipsisVerticalIcon} boxSize={6} />}
                        variant="ghost"
                        size={'sm'}
                      />
                      <MenuList>
                        <MenuItem
                          icon={<Icon as={PencilIcon} boxSize={5} />}
                          onClick={() => {
                            setDashboardToEdit(selectedDashboard);
                            createDashboardModal.onOpen();
                          }}
                        >
                          Edit Page
                        </MenuItem>
                        <MenuItem
                          icon={<Icon as={TrashIcon} boxSize={5} />}
                          color="red.500"
                          onClick={async () => {
                            deleteDashboardModal.onOpen();
                          }}
                        >
                          Delete Page
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </HStack>
                {selectedDashboard.description && (
                  <Text w={['100%', null, null, null, null, '75%']}>
                    {selectedDashboard.description}
                  </Text>
                )}
              </VStack>
              <MeasuredGridView
                key={selectedDashboard.cuid}
                rowHeight={200}
                cols={COLS}
                layout={selectedDashboard.layout}
                onLayoutChange={async layout => {
                  const cleanedDashboardLayout = layout.map(l => ({
                    i: l.i,
                    x: l.x,
                    y: l.y,
                    w: l.w,
                    h: l.h,
                  }));

                  const cleanedNewLayout = layout.map(l => ({
                    i: l.i,
                    x: l.x,
                    y: l.y,
                    w: l.w,
                    h: l.h,
                  }));

                  if (_.isEqual(cleanedDashboardLayout, cleanedNewLayout)) {
                    console.log('Layouts are the same, not saving');
                    return;
                  }

                  const accessToken = await getAccessTokenSilently();
                  // Fail-safe to prevent empty layouts from overwriting the dashboard
                  if (
                    selectedDashboard &&
                    selectedDashboard.layout.length === layout.length
                  ) {
                    // Don't wait for API response to update the UI
                    API.UpdateDashboard(
                      selectedDashboard.cuid,
                      {
                        ...selectedDashboard,
                        layout,
                      },
                      accessToken,
                    );

                    selectedDashboard.layout = layout;
                  }
                }}
                autoSize={true}
              >
                {selectedDashboard.layout.map(item => {
                  const visualization =
                    selectedDashboard.visualization_map[item.i];

                  let menuItems = [];

                  if (visualization.json.type !== 'counter') {
                    menuItems.push(
                      <MenuItem
                        icon={<Icon as={ArrowsPointingOutIcon} boxSize={4} />}
                        onClick={() => {
                          setVisualizationToEdit(visualization);
                          viewVisualizationModal.onOpen();
                        }}
                      >
                        View Full Screen
                      </MenuItem>,
                    );
                  }

                  if (canEdit) {
                    menuItems.push(
                      ...[
                        <MenuItem
                          icon={<Icon as={PencilIcon} boxSize={4} />}
                          onClick={() => {
                            setVisualizationToEdit(visualization);
                            addEditModal.onOpen();
                          }}
                        >
                          Edit Visualization
                        </MenuItem>,
                        <MenuDivider />,
                        <MenuItem
                          color="red.500"
                          icon={<Icon as={TrashIcon} boxSize={4} />}
                          onClick={async () => {
                            const accessToken = await getAccessTokenSilently();
                            const newLayout = selectedDashboard.layout.filter(
                              l => l.i !== item.i,
                            );
                            const copyOfSelectedDashboard = {
                              ...selectedDashboard,
                              layout: newLayout,
                            };
                            API.UpdateDashboard(
                              copyOfSelectedDashboard.cuid,
                              copyOfSelectedDashboard,
                              accessToken,
                            );
                            setSelectedDashboard({
                              ...selectedDashboard,
                              layout: newLayout,
                            });
                          }}
                        >
                          Remove Visualization
                        </MenuItem>,
                      ],
                    );
                  }

                  return (
                    <GridViewItem
                      key={item.i}
                      title={visualization.json.title}
                      menuItems={menuItems.length > 0 ? menuItems : undefined}
                    >
                      <RenderVisualization
                        key={`visualization-${item.i}`}
                        isEditing={false}
                        visualizationJSON={visualization.json}
                      />
                    </GridViewItem>
                  );
                })}
              </MeasuredGridView>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
}
