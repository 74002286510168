import { createContext } from 'react';
import { TUser } from '../models';
import { TUserWithoutRoles } from '../models/user';
import { TOrganization } from '../api/API';
import { TPermissionAction } from '../models/role';

interface UsersContext {
  currentUser: TUser | null;
  setCurrentUser: (user: TUser | null) => void;
  refetchCurrentUser: () => void;
  currentOrganization: TOrganization | null;
  setCurrentOrganization: (organization: TOrganization | null) => void;
  organizationUsers: TUserWithoutRoles[];
  hasFeatureFlag: (key: string) => boolean;
  refetchOrganization: () => void;
  userHasPermission: (
    actions: TPermissionAction[],
    match: 'any' | 'all',
  ) => boolean;
  refetchPermissions: () => void;
}

const UsersContext = createContext<UsersContext>({
  currentUser: null,
  setCurrentUser: () => {},
  refetchCurrentUser: () => {},
  currentOrganization: null,
  setCurrentOrganization: () => {},
  organizationUsers: [],
  hasFeatureFlag: () => false,
  refetchOrganization: () => {},
  userHasPermission: (actions: TPermissionAction[], match: 'any' | 'all') =>
    false,
  refetchPermissions: () => {},
});

export default UsersContext;
