import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  HStack,
  Heading,
  Select,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { TFinding, TUser } from '../../models';
import { useQuery } from 'react-query';
import API from '../../api/API';
import FindingRow from '../FindingsList/FindingRow';
import { Icon, ViewIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { TInventoryModel } from '../../models/inventory_model';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/solid';
import { APIRequestSortBy } from '../../models/utils';
import { EmptyStateDisplay } from '../EmptyStateDisplay';
import { Label } from '../Layout';
import AddFindingModal from '../AddFindingModal';
import { sortFindingsOptions } from '../../pages/ModelFindings';

interface IFindingsWidgetProps {
  variant: 'user-summary' | 'model-inventory-summary' | 'project-summary';
  inventoryModel?: TInventoryModel;
  user?: TUser;
  allowAddFinding?: boolean;
}

export default function FindingsWidget({
  variant,
  inventoryModel,
  user,
  allowAddFinding = true,
}: IFindingsWidgetProps) {
  // *
  // FindingsWidget is a component that displays a list of findings for a given project, inventory model, or user,
  // hence the optional props.
  // This widget is being used in the following places:
  // - Project Home: src/pages/Project/Overview/index.tsx
  // - Model Inventory Home: src/pages/ModelInventory/InventoryModel/Overview/index.tsx
  // - User Home: src/pages/Dashboard/index.tsx
  // *
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [findings, setFindings] = useState<TFinding[]>([]);
  const [sortBy, setSortBy] = useState<APIRequestSortBy>(
    sortFindingsOptions[0],
  );
  const location = useLocation();

  const key = (inventoryModel || user)?.cuid || undefined;
  let { isLoading, isRefetching, data, refetch } = useQuery(
    ['findings', key],
    async () => {
      const accessToken = await getAccessTokenSilently();
      let filters: any = {};
      if (inventoryModel) {
        filters['inventory_models'] = [inventoryModel.cuid];
      }
      return await API.GetFindings(accessToken, page, limit, filters, sortBy);
    },
    {
      onSuccess: data => setFindings(data.results),
    },
  );

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    refetch();
  }, [sortBy]);

  const showPagination = data && data.total > limit;

  const getSeeAllUrl = () => {
    if (inventoryModel) {
      return `/model-inventory/${inventoryModel.cuid}/findings/`;
    } else {
      return '';
    }
  };

  return (
    <VStack w={'full'} gap={2} maxH="100%" justifyContent={'flex-start'}>
      <HStack w={'full'}>
        <Heading
          as="h3"
          fontSize="lg"
          fontWeight="bold"
          data-testid="findings-widget-title"
          hidden={variant === 'user-summary'}
        >
          Model Findings
        </Heading>
        <Spacer />
        <HStack justifyContent={'end'} spacing={0}>
          <Label whiteSpace={'nowrap'} mr={2}>
            Sort by
          </Label>
          <Select
            // bg={useColorModeValue('neutral.100', 'neutral.950')}
            value={sortBy?.label}
            colorScheme="brand"
            focusBorderColor="brand.base"
            onChange={event =>
              setSortBy(
                sortFindingsOptions.find(s => s.label === event.target.value)!,
              )
            }
            size={'sm'}
          >
            {sortFindingsOptions.map(s => (
              <option key={s.label} value={s.label}>
                {s.label}
              </option>
            ))}
          </Select>
        </HStack>
        {location.pathname.includes('dashboard') === false &&
          findings &&
          findings.length > 0 && (
            <AddFindingModal
              triggerLabel="Add Finding"
              onFindingAdded={() => refetch()}
              buttonVariant="ghost"
            />
          )}
      </HStack>

      {isLoading ? (
        <Flex justifyContent={'center'}>
          <Stack justify={'center'} align={'center'}>
            <CircularProgress
              size="40px"
              thickness="2px"
              isIndeterminate
              color="brand.base"
            />
            <Text fontSize={'sm'} color={'inherit'}>
              Please hold...
            </Text>
          </Stack>
        </Flex>
      ) : (
        <>
          {findings && findings.length > 0 ? (
            <>
              <Flex direction="column" gap={1} overflowY="scroll" w={'full'}>
                {findings.map((finding, index) => (
                  <FindingRow key={`finding-${index}`} finding={finding} />
                ))}
              </Flex>
              <HStack
                justifyContent={showPagination ? 'center' : 'end'}
                w={'full'}
              >
                {showPagination && (
                  <HStack>
                    <Button
                      size={'sm'}
                      leftIcon={<Icon as={ArrowLongLeftIcon} boxSize={5} />}
                      onClick={() => setPage(page - 1)}
                      disabled={page === 1}
                      variant={'ghost'}
                    >
                      Prev
                    </Button>
                    <Text fontSize="sm" color={'neutral.600'}>
                      {data?.total} Items
                    </Text>
                    <Button
                      size={'sm'}
                      rightIcon={<Icon as={ArrowLongRightIcon} boxSize={5} />}
                      onClick={() => {
                        setPage(page + 1);
                      }}
                      disabled={data!.total / limit <= page}
                      variant={'ghost'}
                    >
                      Next
                    </Button>
                  </HStack>
                )}

                {getSeeAllUrl() && (
                  <Box alignSelf={'flex-end'}>
                    <Button
                      variant={'ghost'}
                      leftIcon={<ViewIcon />}
                      onClick={() => navigate(getSeeAllUrl()!)}
                    >
                      See all Findings
                    </Button>
                  </Box>
                )}
              </HStack>
            </>
          ) : (
            <VStack>
              <EmptyStateDisplay variant="no-findings">
                <Heading as={'h5'}>No findings to display</Heading>
                {/* Only show this extra message inside a model page */}
                {location.pathname.includes('dashboard') === true ? (
                  <>
                    <Text align={'center'} pb={4}>
                      No findings have been logged for any model.
                      <br />
                      Try registering a model first.
                    </Text>
                    <Button
                      onClick={() => navigate('/model-inventory?register=open')}
                    >
                      Register New Model
                    </Button>
                  </>
                ) : (
                  <>
                    <Text align={'center'} pb={4}>
                      No findings have been logged for this model yet.
                    </Text>
                    <Box hidden={!allowAddFinding}>
                      <AddFindingModal triggerLabel="Add Finding" />
                    </Box>
                  </>
                )}
              </EmptyStateDisplay>
            </VStack>
          )}
        </>
      )}
    </VStack>
  );
}
