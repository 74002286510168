import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Code,
  Divider,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Switch,
  Tag,
  TagLabel,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import API from '../../api/API';
import { PlayIcon } from '@heroicons/react/24/solid';
import { IconButtonProps, RJSFSchema } from '@rjsf/utils';
import { JSONSchema7, JSONSchema7TypeName } from 'json-schema';
import {
  NumberProperty,
  LongTextOptions,
  RJSFSchemaSettings,
  RJSFSchemaSettingsProperty,
  TInventoryModelSchema,
} from '../../models/inventory_model';
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/chakra-ui';
import UsersContext from '../../contexts/UsersContext';
import { Label } from '../Layout';
import ConfirmationAlert from '../ConfirmationAlert';
import { CKEditorWrapper } from '../TextContentEditor/CKEditorWrapper';
import { AddIcon } from '@chakra-ui/icons';
import { Editor } from '@monaco-editor/react';
import { closestCorners, DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from '../SortableItem';
import { CodeTestResponse } from '../../models/custom_field_code';
import { FieldTypeId } from '../../models/json_schemas';
import { schemaTypesConfig } from './schema_types';
import axios from 'axios';
import { Currencies, FormatNumber } from '../../currencyUtils';

declare global {
  interface Window {
    monaco: any;
  }
}

interface TestCodeVariables {
  code: string;
  variables: string;
  execute: boolean;
}

function useMonacoCompletionItems(selectedFieldsOrdered: string[]) {
  const monacoRef = useRef<any>(null);

  useEffect(() => {
    if (monacoRef.current) {
      const disposable =
        monacoRef.current.languages.registerCompletionItemProvider('python', {
          provideCompletionItems: () => {
            const suggestions = selectedFieldsOrdered.map(key => ({
              label: `params.${key}`,
              kind: monacoRef.current.languages.CompletionItemKind.Property,
              insertText: `params.${key}`,
              documentation: `Custom field key: ${key}`,
            }));
            return { suggestions };
          },
        });

      return () => {
        disposable.dispose();
      };
    }
  }, [selectedFieldsOrdered]);

  return monacoRef;
}

interface CustomFieldModalProps {
  schema: TInventoryModelSchema;
  onSave: () => void;
  editKey?: string;
  isOpen: boolean;
  onCustomFieldModalClose: () => void;
}

interface PutInventoryModelSchemaProps {
  schema: RJSFSchema;
  settings: RJSFSchemaSettings;
  operation: Operation;
  properties: {
    key: string;
    schema: JSONSchema7;
    settings: RJSFSchemaSettingsProperty;
  };
}

type Operation = 'add' | 'remove' | 'update';

const initialProperty: JSONSchema7 = {
  type: 'string',
  title: '',
};

const initialPropertySettings: RJSFSchemaSettingsProperty = {
  typeId: FieldTypeId.SingleLine,
};

const defaultCode = `# The \`formula(params)\` Python function calculates a value based on model field inputs.
# The \`params\` object contains the values of the selected model fields from the list on the left.
# Your function should process these inputs and return a result.

def formula(params):
    # Simple threshold check example
    value = 1
    threshold = 80
    if value < threshold:
        return "Low Risk"
    return "High Risk"

# Example advanced formula: Classify models into risk tiers based on weighted criteria.
# Materiality (35%), Criticality (35%), and Uncertainty in Results (30%).
# def formula(params):
#     # Assign numerical values for each criterion
#     risk_values = {"Low": 1, "Medium": 2, "High": 3}
#
#     # Access values from params
#     materiality = params.materiality
#     criticality = params.criticality
#     uncertainty = params.uncertainty
#
#     # Calculate the weighted risk score
#     score = (0.35 * risk_values[materiality] +
#              0.35 * risk_values[criticality] +
#              0.30 * risk_values[uncertainty])
#
#     # Defining thresholds for risk tiers
#     if score >= 2.5:
#         return "High Risk"
#     elif score <= 1.5:
#         return "Low Risk"
#     else:
#         return "Medium Risk"

# Example: Array length check
# def formula(params):
#     if len(params.phase1Approvers) < 3:
#         return "Insufficient Approvers"
#     return "Properly Approved"
`;

interface FieldWithSettings {
  schema: JSONSchema7;
  settings: RJSFSchemaSettingsProperty;
}

interface FieldsWithSettings {
  [key: string]: FieldWithSettings;
}

interface NumberFieldsProps {
  numberOptions: NumberProperty;
  onPropsChange: (numberOptions: NumberProperty) => void;
}

const NumberFields = ({ numberOptions, onPropsChange }: NumberFieldsProps) => {
  const { type, currencyCode, decimalPlaces, abbreviation } = numberOptions;
  return (
    <Stack gap={4} w={'full'}>
      <VStack alignItems={'flex-start'} gap={2}>
        <Label>Number Type</Label>
        <Select
          value={type}
          onChange={e => {
            onPropsChange({
              ...numberOptions,
              type: e.target.value as NumberProperty['type'],
            });
          }}
        >
          <option value={undefined}>Simple</option>
          <option value="currency">Currency</option>
        </Select>
      </VStack>
      {type === 'currency' && (
        <VStack alignItems={'flex-start'} gap={2}>
          <Label>Currency</Label>
          <Select
            value={currencyCode}
            onChange={e => {
              onPropsChange({
                ...numberOptions,
                currencyCode: e.target.value,
              });
            }}
          >
            {Currencies.map(currency => (
              <option key={currency.code} value={currency.code}>
                {currency.flag} {currency.code} - {currency.name}
              </option>
            ))}
          </Select>
        </VStack>
      )}
      <VStack alignItems={'flex-start'} gap={2}>
        <Label>Decimal Places</Label>
        <Select
          value={decimalPlaces}
          onChange={e => {
            onPropsChange({
              ...numberOptions,
              decimalPlaces: parseInt(e.target.value),
            });
          }}
        >
          <option value={0}>0 (1)</option>
          <option value={1}>1 (1.0)</option>
          <option value={2}>2 (1.00)</option>
          <option value={3}>3 (1.000)</option>
          <option value={4}>4 (1.0000)</option>
          <option value={5}>5 (1.00000)</option>
        </Select>
      </VStack>
      <VStack alignItems={'flex-start'} gap={2}>
        <Label>Large Number Abbreviation</Label>
        <Select
          value={abbreviation}
          onChange={e => {
            onPropsChange({
              ...numberOptions,
              abbreviation: e.target.value
                ? (e.target.value as NumberProperty['abbreviation'])
                : undefined,
            });
          }}
        >
          <option value={undefined}>None</option>
          <option value="thousand">Thousand (K)</option>
          <option value="million">Million (M)</option>
          <option value="billion">Billion (B)</option>
        </Select>
      </VStack>
      <VStack alignItems={'flex-start'} gap={2}>
        <Label>Example:</Label>
        <HStack>
          <Text fontSize={'sm'} fontFamily={'mono'}>
            {123456789.12345}
          </Text>
          <Text> &rarr;</Text>
          <Text fontSize={'lg'} fontFamily={'mono'}>
            {FormatNumber(123456789.12345, numberOptions)}
          </Text>
        </HStack>
      </VStack>
    </Stack>
  );
};

export default function CustomFieldModal({
  schema,
  editKey,
  onSave,
  onCustomFieldModalClose,
  isOpen = false,
}: CustomFieldModalProps) {
  const { refetchOrganization } = useContext(UsersContext);
  const { onOpen, onClose } = useDisclosure();
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [key, setKey] = useState('');
  const [description, setDescription] = useState('');
  const [property, setProperty] = useState<JSONSchema7>(initialProperty);
  const [propertySetting, setPropertySetting] =
    useState<RJSFSchemaSettingsProperty>(initialPropertySettings);
  const [arrayAllowsMany, setArrayAllowsMany] = useState(false);
  const [requiredOnRegistration, setRequiredOnRegistration] = useState(false);
  const [enableRichTextFormatting, setEnableRichTextFormatting] =
    useState(false);
  const [keyError, setKeyError] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [richTextTemplateText, setRichTextTemplateText] = useState('');

  // code
  const [code, setCode] = useState(defaultCode);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [availableFields, setAvailableFields] = useState<string[]>([]);
  const [codeTestResponse, setCodeTestResponse] = useState<CodeTestResponse>();
  const [codeTestVariables, setCodeTestVariables] = useState<string>('{}');
  const [codeTestVariablesError, setCodeTestVariablesError] = useState('');
  const editorRef = useRef(null);
  const monacoRef = useMonacoCompletionItems(selectedFields);

  const filteredAvailableFields = useMemo(() => {
    return Object.keys(schema.schema.properties || {})
      .filter(item => !selectedFields.includes(item))
      .filter(k => k !== key);
  }, [schema.schema.properties, selectedFields, key]);

  useEffect(() => {
    setAvailableFields(filteredAvailableFields);
  }, [filteredAvailableFields]);

  useEffect(() => {
    if (selectedFields.length > 0) {
      setPropertySetting({
        ...propertySetting,
        code: {
          ...propertySetting.code,
          variables: selectedFields,
          source: code,
        },
      });
    }

    const newParams: any = {};
    const variables = JSON.parse(codeTestVariables);
    selectedFields.forEach(key => {
      let value: any = '';
      try {
        const def = schema.schema.properties![key] as JSONSchema7;
        if (def.type === 'number') {
          value = 0;
        } else if (def.type === 'boolean') {
          value = false;
        } else if (def.type === 'array') {
          value = [];
        }
      } catch (e) {
        console.error(`Error with key "${key}"`, e);
      }
      newParams[key] = variables[key] || value;
    });

    setCodeTestVariables(JSON.stringify(newParams, null, 2));
  }, [selectedFields]);
  // end code

  useEffect(() => {
    if (editKey) {
      setKey(editKey);
      const _property = schema.schema.properties![editKey] as JSONSchema7;
      setProperty(_property);
      const _propSettings = schema.settings.properties![
        editKey
      ] as RJSFSchemaSettingsProperty;
      setPropertySetting(_propSettings);
      setDescription(schema.settings.properties![editKey]?.description || '');
      setArrayAllowsMany(schema.settings.properties![editKey]?.many || false);
      setRequiredOnRegistration(
        schema.settings.properties![editKey]?.requiredOnRegistration || false,
      );

      if (propertySetting.typeId === FieldTypeId.MultiLine) {
        const existingOptions: LongTextOptions = propertySetting.options || {};
        setEnableRichTextFormatting(
          existingOptions.enableRichTextFormatting || false,
        );
        setRichTextTemplateText(existingOptions.template || '');
      } else if (_propSettings.typeId === FieldTypeId.CodePython) {
        if (_propSettings.code) {
          setCode(_propSettings.code.source);
          setSelectedFields(_propSettings.code.variables);
        }
      }
    }
  }, [editKey]);

  useEffect(() => {
    const forbiddenKeys = [
      'False',
      'None',
      'True',
      'and',
      'as',
      'assert',
      'async',
      'await',
      'break',
      'class',
      'continue',
      'def',
      'del',
      'elif',
      'else',
      'except',
      'finally',
      'for',
      'from',
      'global',
      'if',
      'import',
      'in',
      'is',
      'lambda',
      'nonlocal',
      'not',
      'or',
      'pass',
      'raise',
      'return',
      'try',
      'while',
      'with',
      'yield',
    ];

    // Check if the key is valid (doesn't start with a number and isn't a forbidden Python keyword)
    const isValidKey = (key: string) => {
      // Check if the key starts with a number
      const startsWithNumber = /^\d/.test(key);
      // Check if the key is a Python reserved keyword
      const isForbidden = forbiddenKeys.includes(key);

      return !startsWithNumber && !isForbidden;
    };

    // Validates key is not used on other properties or violates naming rules
    if (key && !editKey) {
      if (key in schema.schema.properties!) {
        setKeyError(true);
      } else if (!isValidKey(key)) {
        setKeyError(true);
      } else {
        setKeyError(false);
      }
    }
  }, [key, editKey, schema.schema.properties]);

  useEffect(() => {
    if (propertySetting.typeId === FieldTypeId.MultiLine) {
      const existingOptions: LongTextOptions = propertySetting.options || {};
      setEnableRichTextFormatting(
        existingOptions.enableRichTextFormatting || false,
      );
      setRichTextTemplateText(existingOptions.template || '');
    }
  }, [propertySetting]);

  const saveSchema = useMutation(
    ['organizations', 'custom_fields'],
    async ({
      schema,
      settings,
      operation,
      properties,
    }: PutInventoryModelSchemaProps) => {
      const accessToken = await getAccessTokenSilently();
      return await API.PutInventoryModelSchema(
        accessToken,
        schema,
        settings,
        operation,
        properties,
      );
    },
    {
      onSuccess: (data, { operation, properties }) => {
        let verb = 'added';
        if (operation === 'update') {
          verb = 'updated';
        } else if (operation === 'remove') {
          verb = 'removed';
        }
        let title = `Model inventory field ${properties.schema.title} ${verb} successfully`;
        toast({
          title,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onSave();
        onModalClose();
        refetchOrganization(); // to update the custom fields on the inventory model when used via user context.
      },
      onError: (error, variables, context) => {
        let errorMessage =
          'An error occurred while saving the Model Inventory Field';

        if (axios.isAxiosError(error) && error.response) {
          errorMessage = error.response.data?.message || errorMessage;
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }

        toast({
          title: 'Error saving Model Inventory Field',
          description: errorMessage,
          status: 'error',
          duration: 10000,
          isClosable: true,
        });
      },
    },
  );

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const title = (event.target as HTMLInputElement).value;
    setProperty({ ...property!, title });
    setPropertySetting({ ...propertySetting, label: title });
    if (!editKey) {
      let key = _.camelCase(title);
      // Check if the key starts with a number and remove leading numbers if necessary.
      if (/^\d/.test(key)) {
        key = key.replace(/^\d+/, ''); // Remove leading numbers
      }
      setKey(key);
    }
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const description = (event.target as HTMLTextAreaElement).value;
    setDescription(description);
    setPropertySetting({ ...propertySetting, description });
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const typeId = event.target.value;
    const selectedType = schemaTypesConfig.find(t => t.typeId === typeId);

    if (!selectedType) {
      console.error('selectedType not found', typeId);
      return;
    }

    const newProperty: JSONSchema7 = {
      ...property,
      title: property?.title,
      type: selectedType?.type as JSONSchema7TypeName,
      format: selectedType?.format,
    };

    if (selectedType?.format) {
      newProperty.format = selectedType?.format;
    }

    if (selectedType.uniqueItems) {
      newProperty.uniqueItems = selectedType.uniqueItems;
    } else {
      if ('uniqueItems' in newProperty) {
        delete newProperty.uniqueItems;
      }
    }

    // array types need to have an items property, otherwise none.
    if (selectedType?.type === 'array') {
      newProperty.items = { type: 'string' };
    } else {
      if ('items' in newProperty) {
        delete newProperty.items;
      }
    }

    setProperty(newProperty);

    setPropertySetting({
      typeId: typeId,
      description: description,
      entity: selectedType?.entity,
      uiSchema: selectedType?.uiSchema,
      label: property?.title,
      many: selectedType.entity ? arrayAllowsMany : undefined,
    });
  };

  const handleArrayAllowsManyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setArrayAllowsMany(event.target.checked);
    setPropertySetting({
      ...propertySetting,
      many: event.target.checked,
    });
  };

  const handleRequiredOnRegistration = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRequiredOnRegistration(event.target.checked);
    setPropertySetting({
      ...propertySetting,
      requiredOnRegistration: event.target.checked,
    });
  };

  const handleEnableRichTextFormatting = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEnableRichTextFormatting(event.target.checked);
    const existingOptions: LongTextOptions = propertySetting.options || {};
    existingOptions.enableRichTextFormatting = event.target.checked;
    setPropertySetting({
      ...propertySetting,
      options: existingOptions,
    });
  };

  const handleRichTextTemplate = (text: string) => {
    setRichTextTemplateText(text);
    // options will be present from the handleEnableRichTextFormatting function
    const existingOptions: LongTextOptions = propertySetting.options!;
    existingOptions.template = text;
    setPropertySetting({
      ...propertySetting,
      options: existingOptions,
    });
  };

  const handleChangeArrayOfCategories = (formData: { enums: string[] }) => {
    const enum_ = formData.enums.filter(e => e!!);
    if (property.type === 'array') {
      setProperty({
        ...property,
        items: {
          ...(property.items as JSONSchema7),
          enum: enum_.length > 0 ? enum_ : undefined,
        },
      });
    } else if (property.type === 'string') {
      setProperty({
        ...property,
        enum: enum_.length > 0 ? enum_ : undefined,
      });
    }
  };

  const onSaveClicked = () => {
    let operation: Operation = 'add';

    if (Object.keys(schema.schema.properties!).includes(key)) {
      operation = 'update';
    }

    schema.schema.properties![key] = property;
    schema.settings.properties![key] = propertySetting;

    saveSchema.mutate({
      schema: schema.schema,
      settings: schema.settings,
      operation,
      properties: {
        key: key,
        schema: property,
        settings: propertySetting,
      },
    });
  };

  const onDeleteClicked = () => {
    setConfirmDelete(true);
  };

  const onDeleteConfirmedClicked = (confirmed: boolean) => {
    setConfirmDelete(false);

    if (!confirmed) {
      return;
    }
    let operation: Operation = 'remove';

    const deletedSchema = schema.schema.properties![key] as JSONSchema7;
    const deletedSettings = schema.settings.properties![key];

    const schemaCopy = _.cloneDeep(schema);
    delete schemaCopy.schema.properties![key];
    delete schemaCopy.settings.properties![key];

    saveSchema.mutate({
      schema: schemaCopy.schema,
      settings: schemaCopy.settings,
      operation,
      properties: {
        key: key,
        schema: deletedSchema,
        settings: deletedSettings,
      },
    });
  };

  const onModalClose = () => {
    if (!editKey) {
      setKey('');
      setDescription('');
      setProperty(initialProperty);
      setPropertySetting(initialPropertySettings);
      setArrayAllowsMany(false);
      setKeyError(false);
      setSelectedFields([]);
      setAvailableFields(filteredAvailableFields);
      setCode(defaultCode);
      setCodeTestResponse(undefined);
      setCodeTestVariables('{}');
      setCodeTestVariablesError('');
    }
    onCustomFieldModalClose();
    onClose();
  };

  const saveIsDisabled =
    _.isEmpty(key) ||
    _.isEmpty(property?.title) ||
    saveSchema.isLoading ||
    keyError;

  const currentTypeSetting = schemaTypesConfig.find(
    t => t.typeId === propertySetting?.typeId,
  );
  function AddButton(props: IconButtonProps) {
    const { icon, iconType, ...btnProps } = props;
    return (
      <Button {...btnProps} leftIcon={<Icon as={AddIcon} boxSize={3} />}>
        Add Option
      </Button>
    );
  }

  const onCodeFieldDragEnd = (e: DragEndEvent) => {
    if (!e.over) return;

    if (e.active.id !== e.over.id) {
      const oldIdx = selectedFields.findIndex(o => o === e.active.id);
      const newIdx = selectedFields.findIndex(o => o === e.over!.id);

      const newArray = arrayMove(selectedFields, oldIdx, newIdx);

      setSelectedFields(newArray);
    }
  };

  const fieldsWithSettingsLookup = useMemo(() => {
    const lookup: FieldsWithSettings = {};
    // foreach schema.schema.properties:
    Object.entries(schema.schema.properties || {}).forEach(([key, _schema]) => {
      const _settings = schema.settings.properties![
        key
      ] as RJSFSchemaSettingsProperty;
      lookup[key] = { schema: _schema as JSONSchema7, settings: _settings };
    });
    return lookup;
  }, [schema.schema.properties, schema.settings.properties]);

  const onAddField = (item: string) => {
    setSelectedFields([...selectedFields, item]);
  };

  const onCodeRemoveField = (id: string) => {
    // remove the item from selectedFields:
    setSelectedFields([...selectedFields.filter(item => item !== id)]);
    // add the item to the availableFields:
    setAvailableFields([...availableFields, id]);
  };

  const onCodeChange = (newCode: any) => {
    setCode(newCode);
    setPropertySetting({
      ...propertySetting,
      code: {
        ...propertySetting.code,
        variables: selectedFields,
        source: newCode,
      },
    });
  };

  const testCode = useMutation(
    ['organizations', 'custom_fields', 'code-test'],
    async ({ code, variables, execute }: TestCodeVariables) => {
      const accessToken = await getAccessTokenSilently();
      return await API.PostCodeTest(
        accessToken,
        code,
        JSON.parse(variables),
        execute,
      );
    },
    {
      onSuccess: data => {
        setCodeTestResponse(data);
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            title: 'Error testing formula',
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      },
    },
  );

  const onCodeTestClicked = () => {
    setCodeTestResponse(undefined);
    testCode.mutate({
      code,
      variables: codeTestVariables,
      execute: true,
    });
  };

  const onCodeTestVariablesChange = (newVariables: string) => {
    try {
      JSON.parse(newVariables);
      setCodeTestVariablesError('');
    } catch (e) {
      if (e instanceof Error) {
        setCodeTestVariablesError(e.message);
      }
    }
    setCodeTestVariables(newVariables);
  };

  const handleTypeSelectChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      handleTypeChange(event);
    },
    [handleTypeChange],
  );

  const selectTypeOptions = useMemo(() => {
    return schemaTypesConfig.map(t => (
      <option key={t.typeId} value={t.typeId}>
        {t.name}
      </option>
    ));
  }, [schemaTypesConfig]);

  return (
    <>
      <Modal
        size={
          propertySetting?.typeId === FieldTypeId.CodePython ? 'full' : 'xl'
        }
        closeOnOverlayClick={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onModalClose}
        trapFocus={false}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          margin={propertySetting?.typeId === FieldTypeId.CodePython ? 16 : 0}
        >
          <ModalHeader>
            {editKey && property
              ? `Edit ${property.title} field`
              : 'Add New Model Inventory Field'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack direction={'row'} gap={4}>
              <VStack
                align="flex-start"
                gap={0}
                w={
                  propertySetting?.typeId === FieldTypeId.CodePython
                    ? '33%'
                    : 'full'
                }
              >
                <FormControl mt={4} isRequired>
                  <Label mb={2}>Type</Label>
                  <Select
                    ref={initialRef}
                    value={propertySetting?.typeId}
                    onChange={handleTypeSelectChange}
                  >
                    {selectTypeOptions}
                  </Select>
                </FormControl>

                <FormControl mt={4} isRequired>
                  <Label mb={2}>Name</Label>
                  <Input
                    autoComplete={'off'}
                    value={property?.title}
                    placeholder="Title"
                    onChange={event =>
                      handleTitleChange(
                        event as React.ChangeEvent<HTMLInputElement>,
                      )
                    }
                  />
                </FormControl>
                <FormControl
                  mt={4}
                  display="flex"
                  alignItems="center"
                  hidden={propertySetting?.typeId === FieldTypeId.CodePython}
                >
                  <Switch
                    id="requiredOnRegistration"
                    isChecked={requiredOnRegistration}
                    onChange={handleRequiredOnRegistration}
                    colorScheme={'brand'}
                  />
                  <Label ml={4}>Required on registration</Label>
                </FormControl>

                <FormControl mt={4}>
                  <Label mb={2}>Description</Label>
                  <Textarea
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder="Describe the field"
                    size="sm"
                  />
                </FormControl>

                {/* For long texts, show toggle to allow for html editing (CKEditor) */}
                {propertySetting?.typeId === FieldTypeId.MultiLine && (
                  <FormControl mt={4} display="flex">
                    <VStack align="flex-start">
                      <HStack spacing={4}>
                        <Switch
                          id="enableRichTextFormatting"
                          isChecked={enableRichTextFormatting}
                          onChange={handleEnableRichTextFormatting}
                        />
                        <Label mb="0">Enable rich text formatting</Label>
                      </HStack>
                      <Box>
                        <FormHelperText>
                          Formatting options include checklists, hyperlinks,
                          headers, code blocks, and more.
                        </FormHelperText>
                      </Box>
                    </VStack>
                  </FormControl>
                )}

                {propertySetting?.typeId === FieldTypeId.MultiLine &&
                  enableRichTextFormatting && (
                    <FormControl mt={4} display="flex">
                      <VStack align="flex-start" w="100%">
                        <Label mb={2}>Template</Label>
                        <Box w="100%">
                          <CKEditorWrapper
                            text={richTextTemplateText}
                            onChange={handleRichTextTemplate}
                            readOnly={false}
                            enabledFeatures={{
                              images: false,
                              comments: false,
                              revisions: false,
                              deleteBlock: false,
                              generateWithAI: false,
                            }}
                            hideTooltip={true}
                            showOutline={true}
                            autoSave={false}
                            withHeight={true}
                          />
                        </Box>
                      </VStack>
                    </FormControl>
                  )}

                {currentTypeSetting?.displayAllowForManyEntities && (
                  <FormControl mt={4} display="flex" alignItems="center">
                    <Switch
                      id="arrayAllowsMany"
                      isChecked={arrayAllowsMany}
                      onChange={handleArrayAllowsManyChange}
                      colorScheme={'brand'}
                    />
                    <Label ml={2}>Allow linking to multiple records</Label>
                  </FormControl>
                )}

                {currentTypeSetting?.displayEnumForm && (
                  <FormControl mt={4}>
                    <Label mb={2}>Options to select from</Label>
                    <FormHelperText>
                      Options defined here will be available for selection.
                    </FormHelperText>
                    <Form
                      schema={{
                        type: 'object',
                        properties: {
                          enums: {
                            type: 'array',
                            title: 'Options',
                            items: {
                              type: 'string',
                            },
                          },
                        },
                      }}
                      formData={{
                        enums:
                          (property.type === 'array'
                            ? (property.items as JSONSchema7)?.enum
                            : property.enum) || [],
                      }}
                      validator={validator}
                      onChange={e => handleChangeArrayOfCategories(e.formData)}
                      uiSchema={{
                        'ui:globalOptions': {
                          label: false,
                        },
                        enums: {
                          'ui:options': {
                            addable: true,
                            orderable: true,
                            removable: true,
                          },
                        },
                        'ui:submitButtonOptions': {
                          norender: true,
                        },
                      }}
                      templates={{ ButtonTemplates: { AddButton } }}
                    />
                  </FormControl>
                )}
                {propertySetting?.typeId === FieldTypeId.Number && (
                  <>
                    <Divider my={4} />
                    <NumberFields
                      numberOptions={
                        propertySetting.numberOptions || {
                          currencyCode: 'USD',
                          decimalPlaces: 0,
                        }
                      }
                      onPropsChange={options => {
                        setPropertySetting({
                          ...propertySetting,
                          numberOptions: options,
                        });
                      }}
                    />
                  </>
                )}
              </VStack>
              {propertySetting?.typeId === FieldTypeId.CodePython && (
                <FormControl mt={4} display="flex" w={'full'}>
                  <VStack align="flex-start" gap={4}>
                    <Stack gap={2}>
                      <Heading as={'h6'}>CALCULATION HANDLER</Heading>
                      <Text>
                        Define a <code>formula(params)</code> function that
                        returns a value using the params dictionary, which
                        includes selected custom field keys. Keep your code
                        simple, avoid imports, and stick to basic operations.
                        Ensure efficiency and avoid complex logic for smooth,
                        secure execution.
                      </Text>
                    </Stack>

                    <HStack alignItems={'flex-start'} gap={4} w="full">
                      <VStack alignItems={'flex-start'} w={'30%'} gap={4}>
                        <Stack gap={2}>
                          <Heading as={'h6'}>AVAILABLE MODEL FIELDS</Heading>
                          <VStack gap={1} alignItems={'flex-start'}>
                            {availableFields.map((item, index) => (
                              <Tag
                                key={index}
                                variant="outline"
                                colorScheme="gray"
                                pl={'1px'}
                                py={'1px'}
                                size={'sm'}
                              >
                                <IconButton
                                  aria-label="Add"
                                  icon={<Icon as={AddIcon} boxSize={3} />}
                                  onClick={() => onAddField(item)}
                                  size={'xs'}
                                  mr={2}
                                  variant={'ghost'}
                                  rounded={'full'}
                                />
                                <TagLabel>
                                  <HStack fontSize={'sm'}>
                                    <Text>{item}</Text>
                                    <Text fontFamily={'mono'}>
                                      {`(${fieldsWithSettingsLookup[item]?.schema.type})`}
                                    </Text>
                                  </HStack>
                                </TagLabel>
                              </Tag>
                            ))}
                          </VStack>
                        </Stack>
                        {selectedFields?.length > 0 && (
                          <>
                            <Divider />
                            <Stack gap={2}>
                              <VStack alignItems={'flex-start'} gap={0}>
                                <Heading as={'h6'}>
                                  CALCULATION HANDLER HAS ACCESS TO THESE MODEL
                                  FIELDS
                                </Heading>

                                <Text>
                                  The order will define how they display when
                                  editable.{' '}
                                  {selectedFields.length === 0 &&
                                    'Select the first field below.'}
                                </Text>
                              </VStack>
                              <VStack alignItems={'flex-start'} gap={0}>
                                <DndContext
                                  collisionDetection={closestCorners}
                                  onDragEnd={onCodeFieldDragEnd}
                                >
                                  <SortableContext
                                    items={selectedFields}
                                    strategy={verticalListSortingStrategy}
                                  >
                                    <VStack gap={1} w={'full'}>
                                      {selectedFields.map(item => {
                                        return (
                                          <SortableItem
                                            key={item}
                                            id={item}
                                            label={`${item} (${fieldsWithSettingsLookup[item]?.schema.type})`}
                                            isChecked={true}
                                            onRemove={onCodeRemoveField}
                                            textProps={{
                                              fontFamily: 'mono',
                                              textTransform: 'none',
                                              fontWeight: 'normal',
                                            }}
                                            size="sm"
                                          />
                                        );
                                      })}
                                    </VStack>
                                  </SortableContext>
                                </DndContext>
                              </VStack>
                            </Stack>
                          </>
                        )}
                      </VStack>
                      <VStack h={'full'} w={'full'}>
                        <Box
                          height={'60vh'}
                          width={'full'}
                          overflow={'hidden'}
                          borderRadius={'md'}
                        >
                          <Editor
                            defaultLanguage="python"
                            theme="vs-dark"
                            defaultValue={code}
                            value={code}
                            onChange={onCodeChange}
                            onMount={(editor, monaco) => {
                              editorRef.current = editor;
                              monacoRef.current = monaco;
                            }}
                          />
                        </Box>
                        <Accordion w={'full'}>
                          <AccordionItem>
                            <AccordionButton
                              as={Button}
                              variant={'ghost'}
                              rounded={0}
                            >
                              <Text w={'full'} textAlign={'left'}>
                                Test Calculation
                              </Text>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel m={0} p={0}>
                              <Stack
                                p={4}
                                w={'full'}
                                borderLeft={'4px solid'}
                                borderColor={'yellow.200'}
                                bg={useColorModeValue('white', 'neutral.850')}
                              >
                                <Text mt={0}>
                                  The testing area automatically updates with
                                  the keys you add or remove from the model
                                  fields. Enter test values for these keys and
                                  click "Test Formula" to run the code and see
                                  the output. This lets you quickly validate
                                  your formula before saving.
                                </Text>
                                <Textarea
                                  style={{ fontFamily: 'monospace' }}
                                  borderColor={'neutral.200'}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLTextAreaElement>,
                                  ) => {
                                    onCodeTestVariablesChange(
                                      (event.target as HTMLTextAreaElement)
                                        .value,
                                    );
                                  }}
                                  fontSize={'sm'}
                                  value={codeTestVariables}
                                ></Textarea>
                                <Alert
                                  status={'warning'}
                                  hidden={codeTestVariablesError.length === 0}
                                >
                                  <AlertIcon />
                                  <AlertTitle>Test Variables</AlertTitle>
                                  <AlertDescription>
                                    {codeTestVariablesError}
                                  </AlertDescription>
                                </Alert>
                                {codeTestResponse && (
                                  <>
                                    {codeTestResponse.success ? (
                                      <Alert status="success">
                                        <AlertIcon />
                                        <AlertTitle>
                                          The calculation is valid.
                                        </AlertTitle>
                                        <AlertDescription>
                                          <Code>
                                            Result: {codeTestResponse?.result}
                                          </Code>
                                        </AlertDescription>
                                      </Alert>
                                    ) : (
                                      <Alert
                                        status="error"
                                        flexDirection="column"
                                        alignItems="start"
                                        fontSize={'sm'}
                                        variant="left-accent"
                                      >
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          width="100%"
                                        >
                                          <AlertIcon />
                                          <Box flex="1">
                                            <AlertTitle>
                                              The formula function is not valid.
                                            </AlertTitle>
                                            <AlertDescription>
                                              {codeTestResponse?.message}
                                            </AlertDescription>
                                          </Box>
                                        </Box>
                                        {codeTestResponse?.line_number && (
                                          <Box mt={4}>
                                            <AlertDescription>
                                              <strong>Line Number:</strong>{' '}
                                              {codeTestResponse.line_number}
                                            </AlertDescription>
                                          </Box>
                                        )}
                                        {codeTestResponse?.traceback && (
                                          <Box mt={4}>
                                            <AlertDescription>
                                              <strong>Traceback:</strong>
                                              <Code
                                                whiteSpace="pre-wrap"
                                                display="block"
                                                mt={2}
                                              >
                                                {codeTestResponse.traceback}
                                              </Code>
                                            </AlertDescription>
                                          </Box>
                                        )}
                                        {codeTestResponse?.type && (
                                          <Box mt={4}>
                                            <AlertDescription>
                                              <strong>Error Type:</strong>{' '}
                                              {codeTestResponse.type}
                                            </AlertDescription>
                                          </Box>
                                        )}
                                      </Alert>
                                    )}
                                  </>
                                )}

                                <Button
                                  variant={'primary'}
                                  alignSelf={'flex-end'}
                                  onClick={onCodeTestClicked}
                                  isLoading={testCode.isLoading}
                                  leftIcon={<Icon as={PlayIcon} boxSize={4} />}
                                  isDisabled={codeTestVariablesError.length > 0}
                                >
                                  Test Calculation
                                </Button>
                              </Stack>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </VStack>
                    </HStack>
                  </VStack>
                </FormControl>
              )}
            </Stack>
            {/*<Text fontSize={'xs'}>*/}
            {/*  <pre>{JSON.stringify(property, null, 2)}</pre>*/}
            {/*  <hr />*/}
            {/*  <pre>{JSON.stringify(propertySetting, null, 2)}</pre>*/}
            {/*</Text>*/}

            {editKey && (
              <ConfirmationAlert
                title={`Deleting field: ${editKey}`}
                dialogBody={'Are you sure?'}
                open={confirmDelete}
                onConfirm={onDeleteConfirmedClicked}
              />
            )}
          </ModalBody>

          <ModalFooter>
            {editKey && (
              <>
                <Button
                  variant={'ghost'}
                  onClick={onDeleteClicked}
                  isLoading={false}
                >
                  Delete
                </Button>
                <Spacer />
              </>
            )}
            <HStack gap={2}>
              <Button variant={'ghost'} onClick={onModalClose} disabled={false}>
                Cancel
              </Button>
              <Button
                isLoading={false}
                onClick={onSaveClicked}
                isDisabled={saveIsDisabled}
                variant={'primary'}
              >
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
