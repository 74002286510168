import { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Settings from './pages/Settings';
import Organization from './pages/Settings/Organization';
import UserDirectory from './pages/Settings/UserDirectory';
import SingleSignOn from './pages/SingleSignOn';
import { LoadingContainer } from './components/LoadingContainer';
import { useAuth0 } from '@auth0/auth0-react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Chart, registerables } from 'chart.js';
import Templates from './pages/Settings/Templates';
import NotFound from './pages/NotFound';
import ModelInventory from './pages/ModelInventory';
import InventoryModel from './pages/ModelInventory/InventoryModel';
import Dashboard from './pages/Dashboard';
import { useQuery } from 'react-query';
import API from './api/API';
import { TOrganization } from './api/API';
import { setOrganizationRetrievalMethod } from './api/API';
import UsersContext from './contexts/UsersContext';
import AppSidebarLayout from './components/Layout/AppSidebarLayout';
import Onboarding from './pages/Onboarding';
import { TUser } from './models';
import { SettingsSidebar } from './components/SettingsSidebar';
import Groups from './pages/Settings/Groups';
import Profile from './pages/Settings/Profile';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Checkbox,
  HStack,
  Link,
  useColorMode,
  useToast,
  VStack,
  Text,
  Button,
} from '@chakra-ui/react';
import CustomFields from './pages/Settings/CustomFields';
import Template from './pages/Settings/Templates/Template';
import Invitation from './pages/Settings/Invitation';
import { TRiskArea } from './models/risk_area';
import RiskAreaContext from './contexts/RiskAreaContext';
import { CONFIG } from './config';
import ModelFindings from './pages/ModelFindings';
import Approvals from './pages/Approvals';
import Statuses from './pages/Settings/Statuses';
import Workflows from './pages/Settings/Workflows';
import Roles from './pages/Settings/Roles';
import RoleDetails from './pages/Settings/RoleDetails';
import Permissions from './pages/Settings/Permissions';
import { TPermissionAction } from './models/role';
import RiskAreas from './pages/Settings/RiskAreas';
import { AITermsOfUseUrl } from './utils';
import Homepage from './pages/Homepage';
import VerificationErrorPage from './pages/VerificationErrorPage';
import NewReports from './pages/NewReports';
import StyleGuide from './pages/StyleGuide';
import { sprig } from '@sprig-technologies/sprig-browser';
import { Auth0PrivateRoute } from './components/PrivateRoute';
import { datadogRum } from '@datadog/browser-rum';

Chart.register(...registerables);

interface AppState {
  inviteCuid: string | null;
}

export const Sprig = sprig.configure({
  environmentId: CONFIG.SPRIG_ENVIRONMENT_ID,
});

const AITermsOfUseAlert = () => {
  const [acceptedTermsOfAIUse, setHasAcceptedTermsOfAIUse] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const accessToken = await getAccessTokenSilently();
    try {
      await API.PutUserAcceptTerms(accessToken, false, true);
      window.location.reload();
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Alert status="info" variant="subtle" colorScheme="cyan">
      <HStack alignItems="flex-start">
        <AlertIcon />
        <VStack alignItems="flex-start" gap={2}>
          <AlertTitle>Please review our new AI Terms of Use</AlertTitle>

          <AlertDescription>
            A new version of the{' '}
            <Link
              textDecoration={'underline'}
              href={AITermsOfUseUrl}
              isExternal
            >
              AI Terms of Use
            </Link>{' '}
            has been released. Please review and accept the terms before you
            continue.
          </AlertDescription>
          <Checkbox
            isRequired
            // size={'lg'}
            isChecked={acceptedTermsOfAIUse}
            onChange={e => setHasAcceptedTermsOfAIUse(e.target.checked)}
          >
            <Text fontSize={'md'}>Yes, I accept the terms of AI use</Text>
          </Checkbox>
          <Button
            variant="primary"
            isLoading={isSubmitting}
            isDisabled={!acceptedTermsOfAIUse}
            alignSelf="flex-end"
            onClick={!isSubmitting ? handleSubmit : undefined}
          >
            Submit
          </Button>
        </VStack>
      </HStack>
    </Alert>
  );
};

function MainApp() {
  const {
    user,
    isAuthenticated,
    isLoading: isUserLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const ldClient = useLDClient();

  const queryParameters = new URLSearchParams(window.location.search);
  const appStateJSON = queryParameters.get('appStateJSON');
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState<TUser | null>(null);
  const [currentOrganization, setCurrentOrganization] =
    useState<TOrganization | null>(null);
  const [riskAreas, setRiskAreas] = useState<TRiskArea[]>([]);
  const toast = useToast();
  const [termsToastAlreadyShown, setTermsToastAlreadyShown] = useState(false);

  const transientStateFrom_ = (anAppStateJSON: string | null) => {
    const result: AppState = { inviteCuid: null };
    if (anAppStateJSON) {
      const redirectState = JSON.parse(anAppStateJSON);
      result.inviteCuid = redirectState.inviteCuid;
    }
    return result;
  };

  const transientState = useMemo(
    () => transientStateFrom_(appStateJSON),
    [appStateJSON],
  );

  useEffect(() => {
    // get the colorMode from the server's user settings and set it
    if (currentUser && colorMode) {
      if ((currentUser?.ui_settings?.colorMode || 'light') !== colorMode) {
        toggleColorMode();
      }
    }

    // used by API to fetch current organization and set header
    setOrganizationRetrievalMethod(() => {
      return currentOrganization;
    });
  }, [currentUser, currentOrganization, colorMode]);

  const { isLoading: isAcceptingInvite } = useQuery(
    ['me', 'invite'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.PostInvitationAccept(
        accessToken,
        transientState.inviteCuid,
      );
    },
    {
      enabled:
        isAuthenticated &&
        location.pathname !== '/onboarding' &&
        transientState.inviteCuid !== null,
      onSuccess: user => {
        transientState.inviteCuid = null;
      },
    },
  );

  const {
    data: organization,
    isLoading: isOrganizationLoading,
    refetch: refetchOrganization,
  } = useQuery(
    ['me', 'organizations', currentOrganization?.cuid],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetOrganization(accessToken);
    },
    {
      enabled:
        isAuthenticated &&
        location.pathname !== '/onboarding' &&
        currentUser !== null,
      onSuccess: organization => {
        setCurrentOrganization(organization);
      },
    },
  );

  const { isLoading: isCurrentUserLoading, refetch: refetchCurrentUser } =
    useQuery(
      ['me', 'users'],
      async () => {
        const accessToken = await getAccessTokenSilently();
        return await API.GetCurrentUser(accessToken);
      },
      {
        enabled:
          isAuthenticated &&
          location.pathname !== '/onboarding' &&
          !isAcceptingInvite,
        onSuccess: user => {
          if (!user.belongs_to_org) {
            navigate('/onboarding');
          }
          setCurrentUser(user);
        },
      },
    );

  const { data: organizationUsers, isLoading: isOrgUsersLoading } = useQuery(
    ['me', 'organization', 'users', currentOrganization?.cuid],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetOrganizationUsers(accessToken);
    },
    {
      enabled:
        isAuthenticated &&
        location.pathname !== '/onboarding' &&
        currentUser !== null,
    },
  );

  if (isAuthenticated) {
    (window as any).heap.identify(user!.email);

    if (currentOrganization && currentOrganization.roles) {
      const roles = currentOrganization.roles.map(role => role.name).join(', ');
      (window as any).heap.addUserProperties({
        organization: currentOrganization.name,
        organization_cuid: currentOrganization.cuid,
        roles: roles,
        subscription_type: currentOrganization.subscription.type,
      });
    }

    if (
      CONFIG.APP_ENV !== 'development' &&
      currentUser &&
      currentOrganization
    ) {
      datadogRum.setUser({
        id: currentUser.cuid,
        email: currentUser.email,
        organization: currentOrganization.name,
        organization_cuid: currentOrganization.cuid,
      });
    }
  }

  useQuery(
    ['organization-risk-areas', currentOrganization?.cuid],
    async () => {
      const accessToken = await getAccessTokenSilently();
      // TODO: handling paging
      return API.GetRiskAreas(accessToken);
    },
    {
      enabled:
        isAuthenticated &&
        location.pathname !== '/onboarding' &&
        currentUser !== null,
      onSuccess: pagedData => {
        // TODO: handling paging
        setRiskAreas(pagedData.results);
      },
      onError: err => {
        // track errors
      },
    },
  );

  const {
    data: userOrgPermissions,
    isLoading: isLoadingOrgPermissions,
    refetch: refetchPermissions,
  } = useQuery(
    ['me', 'organization', 'permissions', currentOrganization?.cuid],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetUserPermissions(accessToken);
    },
    {
      enabled:
        isAuthenticated &&
        location.pathname !== '/onboarding' &&
        currentUser !== null,
    },
  );

  const userHasPermission = useCallback(
    (actions: TPermissionAction[], match: 'any' | 'all') => {
      if (!userOrgPermissions) return false;
      if (match === 'any') {
        return actions.some(action =>
          userOrgPermissions.some(permission => permission === action),
        );
      } else {
        return actions.every(action =>
          userOrgPermissions.some(permission => permission === action),
        );
      }
    },
    [userOrgPermissions],
  );

  const hasFeatureFlag = useMemo(() => {
    // this is a temporary solution until we have a proper feature flag system,
    // use hasFeatureFlag('feature-key') to check if the feature is enabled
    if (organization) {
      return (key: string) =>
        organization.feature_flags.hasOwnProperty(key) &&
        Boolean(organization.feature_flags[key]);
    }
    return (key: string) => false;
  }, [organization]);

  useEffect(() => {
    // True if the user is authenticated, not onboarding,
    // and has not accepted the AI terms of use
    if (
      !termsToastAlreadyShown &&
      isAuthenticated &&
      location.pathname !== '/onboarding' &&
      currentUser &&
      !currentUser.accepted_ai_terms
    ) {
      setTermsToastAlreadyShown(true);
      toast({
        duration: null,
        isClosable: false,
        position: 'bottom-right',
        render: () => <AITermsOfUseAlert />,
      });
    }
  }, [isAuthenticated, location.pathname, currentUser, termsToastAlreadyShown]);

  // update ld client identity when user is authenticated
  useEffect(() => {
    if (CONFIG.USE_LAUNCHDARKLY && isAuthenticated && user && organization) {
      // setting to multi allows us to segment and release by user or org level
      ldClient?.identify({
        kind: 'multi',
        user: {
          key: user?.email,
          email: user?.email,
          name: user?.name,
        },
        organization: {
          key: organization?.cuid,
          name: organization?.name,
        },
      });

      user.email && Sprig.setEmail(user.email);
      user.name && Sprig.setAttribute('name', user.name);
      organization.name &&
        Sprig.setAttribute('organization', organization.name);
    }
  }, [isAuthenticated, user, organization]);

  const isLoading =
    isAcceptingInvite ||
    isUserLoading ||
    isCurrentUserLoading ||
    isOrgUsersLoading ||
    isOrganizationLoading ||
    isLoadingOrgPermissions;

  return (
    <UsersContext.Provider
      value={{
        currentOrganization: currentOrganization || null,
        currentUser: currentUser || null,
        setCurrentUser,
        organizationUsers: organizationUsers || [],
        setCurrentOrganization,
        hasFeatureFlag,
        refetchOrganization,
        userHasPermission,
        refetchPermissions,
        refetchCurrentUser,
      }}
    >
      <RiskAreaContext.Provider value={{ riskAreas, setRiskAreas }}>
        <LoadingContainer isLoading={isLoading}>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/verify-email" element={<VerificationErrorPage />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/sso" element={<SingleSignOn />} />
            <Route path="/styleguide" element={<StyleGuide />} />
            <Route
              path="*"
              element={
                <AppSidebarLayout>
                  <SettingsSidebar />
                  <Routes>
                    <Route element={<Auth0PrivateRoute />}>
                      <Route path="/dashboard/" element={<Dashboard />} />

                      <Route path="/settings/" element={<Settings />} />
                      <Route
                        path="/settings/organization/"
                        element={<Organization />}
                      />
                      <Route
                        path="/settings/risk-areas/"
                        element={<RiskAreas />}
                      />
                      <Route path="/settings/profile/" element={<Profile />} />
                      <Route path="/settings/groups/" element={<Groups />} />
                      <Route
                        path="/settings/roles/:roleCUID"
                        element={<RoleDetails />}
                      />
                      <Route path="/settings/roles" element={<Roles />} />
                      <Route
                        path="/settings/permissions"
                        element={<Permissions />}
                      />
                      <Route
                        path="/settings/user-directory/"
                        element={<UserDirectory />}
                      />
                      <Route
                        path="/settings/invitation/"
                        element={<Invitation />}
                      />
                      <Route
                        path="/settings/inventory-model-custom-fields/"
                        element={<CustomFields />}
                      />
                      <Route
                        path="/settings/statuses/*"
                        element={<Statuses />}
                      />
                      <Route
                        path="/settings/workflows/*"
                        element={<Workflows />}
                      />

                      <Route
                        path="/settings/templates/"
                        element={<Templates />}
                      />
                      <Route
                        path="/settings/templates/:id/:documentType/:versionId/*"
                        element={<Template />}
                      />
                      <Route
                        path="/model-inventory"
                        element={<ModelInventory />}
                      />
                      <Route
                        path="/model-inventory/:id/*"
                        element={<InventoryModel />}
                      />
                      <Route path="/analytics" element={<NewReports />} />
                      <Route path="/approvals" element={<Approvals />} />
                      <Route
                        path="/model-findings"
                        element={<ModelFindings />}
                      />
                    </Route>
                  </Routes>
                </AppSidebarLayout>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </LoadingContainer>
      </RiskAreaContext.Provider>
    </UsersContext.Provider>
  );
}

export default MainApp;
