import { TDashboardVisualizationJSON } from '../../../../models/report';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import API from '../../../../api/API';
import ReactECharts from 'echarts-for-react';
import { Box, Spinner } from '@chakra-ui/react';

type ReportingBarChartProps = {
  chartClick: (params: any, data: any[]) => void;
  visualizationJSON: TDashboardVisualizationJSON;
};

const ReportPieChart = ({
  chartClick,
  visualizationJSON: {
    dataset,
    metrics = [],
    grouping = [],
    sorting,
    filtering,
  },
}: ReportingBarChartProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!dataset || metrics.length === 0 || grouping.length === 0) {
        return;
      }
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const results = await API.GetReportingData(
        accessToken,
        dataset,
        metrics,
        grouping,
        sorting,
        filtering,
      );
      setData(results);
      setLoading(false);
    })();
  }, [dataset, metrics, grouping, sorting, filtering]);

  const option = useMemo(() => {
    return {
      grid: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
        containLabel: true,
      },
      tooltip: {
        trigger: 'item',
        confine: true,
      },
      series: [
        {
          type: 'pie',
          radius: '75%',
          data: data.map(d => ({ value: d.metric, name: d.group[0] })),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          label: {
            color: '#6e7079',
          },
        },
      ],
    };
  }, [data]);

  const onEvents = useMemo(() => {
    return {
      click: (params: any) => {
        chartClick(params, data);
      },
    };
  }, [chartClick, data]);

  if (loading) {
    return (
      <Box
        display="flex"
        flex={1}
        h="full"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <ReactECharts
      option={option}
      style={{ height: '100%', width: '100%' }}
      notMerge
      onEvents={onEvents}
    />
  );
};

export default ReportPieChart;
