import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Checkbox,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import API from '../../api/API';

import {
  DocumentArrowDownIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';
import MoreInfoPopOver from '../MoreInfoPopOver';
import { TInventoryModel } from '../../models/inventory_model';

interface DownloadModalProps {
  inventoryModel: TInventoryModel;
  currentDocumentType: string | 'documentation' | 'validation_report';
}

const DownloadModal = ({
  inventoryModel,
  currentDocumentType = 'documentation',
}: DownloadModalProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [includeComments, setIncludeComments] = useState(false);
  const [includeActivityFeed, setIncludeActivityFeed] = useState(false);
  const [includeStatus, setIncludeStatus] = useState(false);
  const [includeFindings, setIncludeFindings] = useState(false);
  const [format, setFormat] = useState('docx');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState('');
  const ref = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (!includeActivityFeed) {
      setIncludeStatus(false);
      setIncludeFindings(false);
    }
  }, [includeActivityFeed]);

  const getFilename = () => {
    const date = new Date();
    const dateString = date.toISOString().substring(0, 10);
    return `${inventoryModel.name.replaceAll(
      ' ',
      '',
    )}_${dateString}-${currentDocumentType}.${format}`;
  };

  const downloadDocument = async () => {
    try {
      setError('');
      setDownloading(true);
      let response;
      const accessToken = await getAccessTokenSilently();
      if (currentDocumentType === 'documentation') {
        response = await API.DownloadDocumentationReport(
          inventoryModel,
          includeComments,
          includeActivityFeed,
          includeStatus,
          includeFindings,
          format,
          getFilename(),
          accessToken,
        );
      } else {
        response = await API.DownloadValidationReport(
          inventoryModel,
          includeComments,
          includeActivityFeed,
          includeStatus,
          includeFindings,
          'docx',
          getFilename(),
          accessToken,
        );
      }

      const blobUrl = URL.createObjectURL(new Blob([response.data]));
      setDownloadUrl(blobUrl);

      // ref.current?.click(); can run before downloadFilename new state is ready
      setTimeout(() => {
        ref.current?.click();
        URL.revokeObjectURL(blobUrl);
      }, 100);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message as string);
      }
    } finally {
      setDownloading(false);
    }
  };

  return (
    <>
      <a href={downloadUrl} hidden ref={ref} download={getFilename()} />
      <Button
        variant={'ghost'}
        onClick={onToggle}
        leftIcon={<Icon as={ArrowDownTrayIcon} boxSize={5} />}
      >
        Export Document
      </Button>

      <Modal isCentered onClose={onClose} isOpen={isOpen} size={'3xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Export{' '}
            {currentDocumentType === 'documentation'
              ? 'Model Documentation'
              : 'Validation Report'}{' '}
            <MoreInfoPopOver
              title="Export Document"
              link="https://docs.validmind.ai/guide/model-documentation/export-documentation.html"
              placement="bottom"
              iconProps={{
                ml: 1,
              }}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Box>
                <Text>Choose the file format that works best for you.</Text>
              </Box>
              <Box hidden>
                <Text fontSize={'lg'} fontWeight={'semibold'}>
                  1. Configure what goes into your document
                </Text>
              </Box>

              <Box pl={5} hidden>
                <Checkbox
                  isChecked={includeComments}
                  onChange={e => setIncludeComments(e.target.checked)}
                >
                  Include comment threads
                </Checkbox>
              </Box>
              <Box pl={10} hidden>
                <Checkbox
                  disabled={true}
                  isChecked={false}
                  onChange={e => console.log(e.target.checked)}
                >
                  Only “📎 Pinned” comment threads
                </Checkbox>
              </Box>
              <Box pl={5} hidden>
                <Checkbox
                  isChecked={includeActivityFeed}
                  onChange={e => setIncludeActivityFeed(e.target.checked)}
                >
                  Include section activity logs
                </Checkbox>
              </Box>
              <Box pl={10} hidden>
                <Checkbox
                  disabled={!includeActivityFeed}
                  isChecked={includeStatus}
                  onChange={e => setIncludeStatus(e.target.checked)}
                >
                  Project status changes
                </Checkbox>
              </Box>
              <Box pl={10} hidden>
                <Checkbox
                  disabled={!includeActivityFeed}
                  isChecked={includeFindings}
                  onChange={e => setIncludeFindings(e.target.checked)}
                >
                  Project finding events
                </Checkbox>
              </Box>

              <Box>
                <Text fontSize={'lg'} fontWeight={'semibold'}>
                  1. Pick a file format
                </Text>
              </Box>

              <Box pl={5}>
                <RadioGroup defaultValue="docx" onChange={setFormat}>
                  <Stack>
                    <Radio value={'docx'}>
                      Microsoft Word (<Badge>.docx</Badge>)
                    </Radio>
                    {/* <Radio value={'pdf'} >
                      Portable Document Format (.pdf)
                    </Radio> */}
                  </Stack>
                </RadioGroup>
              </Box>

              <Box>
                <Text fontSize={'lg'} fontWeight={'semibold'}>
                  2. Download your file
                </Text>
              </Box>
              {format === 'docx' && (
                <Box pl={5}>
                  <Text>
                    To generate the table of contents, click <Badge>Yes</Badge>{' '}
                    when prompted to update the fields in the document.
                  </Text>
                  <Spacer h={8} />
                  <Text textAlign={'center'}>
                    <strong>{getFilename()} </strong>is ready to download!
                  </Text>
                </Box>
              )}
              {error && (
                <Box>
                  <Alert status="error" variant="left-accent">
                    <AlertIcon />
                    <Box>
                      <AlertTitle>
                        Something went wrong configuring your download
                      </AlertTitle>
                      <AlertDescription>
                        Please refresh the page and try to export the model
                        documentation again.
                      </AlertDescription>
                    </Box>
                  </Alert>
                </Box>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter justifyContent={'flex-start'}>
            <Spacer />
            <Button
              isLoading={downloading}
              leftIcon={<Icon as={DocumentArrowDownIcon} boxSize={5} />}
              onClick={downloadDocument}
              variant={'primary'}
            >
              Download File
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DownloadModal;
