import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Progress,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  Text,
  useColorModeValue,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { ContentPageTitle } from '../../components/Layout';
import { LoadingContainer } from '../../components/LoadingContainer';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import API from '../../api/API';
import { allFiltersEmpty, displayFormatedDate } from '../../utils';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  FunnelIcon,
} from '@heroicons/react/24/solid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { APIRequestSortBy } from '../../models/utils';
import {
  BarsArrowDownIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/20/solid';
import { Copyright } from '../../components/Copyright';
import { EmptyStateDisplay } from '../../components/EmptyStateDisplay';
import { CubeIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { DataTable } from '../../components/DataTable';
import GetStartedChecklist from '../../components/GetStartedChecklist';

import { TFinding } from '../../models';
import { SeverityTagColor, StatusTagColor } from '../../colors';
import { FindingFilters, getFindingDisplayStatus } from '../../models/finding';
import ModelFindingsFilters from '../../components/ModelFindingsFilters';
import TableColumnPicker from '../../components/TableColumnPicker';
import { useUserUISettings } from '../../hooks/useUserUISettings';
import AvatarProxy from '../../components/AvatarProxy';
import MoreInfoPopOver from '../../components/MoreInfoPopOver';
import { statusText } from '../../components/FindingsList/FindingRow';

const ALL_COLUMNS = [
  {
    Header: 'Title',
    accessor: 'title',
    Cell: ({ row }: any) => {
      return <Text fontWeight={'semibold'}>{row.values.title}</Text>;
    },
    sortField: 'title',
    sortType: 'Alphabet',
  },
  {
    Header: 'Severity',
    accessor: 'severity',
    Cell: ({ value }: any) => {
      return (
        <Tag
          colorScheme={SeverityTagColor[value.name as keyof object]}
          size={'sm'}
        >
          {value.name}
        </Tag>
      );
    },
    sortField: 'severity',
    sortType: 'Severity',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }: any) => {
      const finding = row.values as TFinding;
      const status = getFindingDisplayStatus(finding);
      return (
        <Tag
          data-testid="status-badge"
          colorScheme={StatusTagColor[status as keyof object]}
          size="sm"
        >
          {statusText[status as keyof object]}
        </Tag>
      );
    },
    sortField: 'status',
    sortType: 'Alphabet',
  },
  {
    Header: 'Inventory Model',
    accessor: 'inventory_model.name',
    Cell: ({ value }: any) => {
      return (
        <HStack gap={1} alignItems={'flex-start'}>
          <Icon boxSize={4} as={CubeIcon} mt={0.5} />
          <Text>{value}</Text>
        </HStack>
      );
    },
    sortField: 'inventory_model.name',
    sortType: 'Alphabet',
  },
  {
    Header: 'Risk Area',
    accessor: 'risk_area',
    Cell: ({ value }: any) => {
      if (!value) return null;
      return <Text>{value.name}</Text>;
    },
    sortField: 'risk_area.name',
    sortType: 'Alphabet',
  },
  {
    Header: 'Business Unit',
    accessor: 'business_unit.name',
    sortField: 'business_unit.name',
    sortType: 'Alphabet',
  },
  {
    Header: 'Assigned To',
    accessor: 'owner',
    Cell: ({ value }: any) => {
      if (!value) {
        return null;
      }
      return (
        <Box>
          <Tag key={value.cuid} size={'md'}>
            <AvatarProxy
              src={value.picture}
              size="xs"
              name={value.name}
              ml={-2}
              mr={2}
            />
            <TagLabel>{value.name}</TagLabel>
          </Tag>
        </Box>
      );
    },
    sortField: 'owner',
    sortType: 'Alphabet',
  },
  {
    Header: 'Due Date',
    accessor: 'due_at',
    Cell: ({ row }: any) => {
      const finding = row.values as TFinding;
      const status = getFindingDisplayStatus(finding);

      if (!finding.due_at) {
        return null;
      }

      const isOverdue = status === 'past_due';
      return (
        <Wrap spacing={0}>
          <WrapItem ml={0}>
            <Tag pl={0} colorScheme={isOverdue ? 'red' : ''} size={'sm'}>
              {isOverdue && (
                <TagLeftIcon boxSize={6} as={ExclamationCircleIcon} />
              )}
              {displayFormatedDate(finding.due_at)}
            </Tag>
          </WrapItem>
          <WrapItem ml={3}>
            {isOverdue && (
              <Text color={'red.600'} fontSize={'sm'}>
                Overdue by {dayjs().diff(dayjs(finding.due_at * 1000), 'days')}{' '}
                days
              </Text>
            )}
          </WrapItem>
        </Wrap>
      );
    },
    sortField: 'due_at',
    sortType: 'Date',
  },
  {
    Header: 'Creation date',
    accessor: 'created_at',
    Cell: ({ value }: any) => {
      if (!value) {
        return null;
      }
      return <Tag whiteSpace={'nowrap'}>{displayFormatedDate(value)}</Tag>;
    },
    sortField: 'created_at',
    sortType: 'Date',
  },
] as const;

export const sortFindingsOptions: APIRequestSortBy[] = [
  {
    label: 'Most severe first',
    field: 'severity',
    order: 'asc',
  },
  {
    label: 'Most recent first',
    field: 'created_at',
    order: 'desc',
  },
  {
    label: 'Closest due date first',
    field: 'due_at',
    order: 'asc',
  },
];

const initialSortBy = sortFindingsOptions[0];

type FilterDefinition = {
  accessors?: {
    id?: string;
    label?: string;
    picture?: string;
  };
  tag: string;
};

const filterDefs: { [key: string]: FilterDefinition } = {
  business_units: {
    accessors: {
      id: 'cuid',
      label: 'name',
    },
    tag: 'Business Unit',
  },
  status: {
    tag: 'Status',
  },
  assignees: {
    accessors: {
      id: 'cuid',
      label: 'name',
      picture: 'picture',
    },
    tag: 'Assigned To',
  },
  risk_areas: {
    accessors: {
      id: 'cuid',
      label: 'name',
    },
    tag: 'Risk Area',
  },
  severities: {
    accessors: {
      id: 'id',
      label: 'name',
    },
    tag: 'Severity',
  },
  inventory_models: {
    accessors: {
      id: 'cuid',
      label: 'name',
    },
    tag: 'Inventory Model',
  },
};

type SortType = typeof ALL_COLUMNS[number]['sortType'];

const getLabelFromSortType = (sortType: SortType, order: 'asc' | 'desc') => {
  if (sortType === 'Alphabet') {
    return order === 'asc' ? 'A to Z' : 'Z to A';
  }
  if (sortType === 'Date') {
    return order === 'asc' ? 'Oldest first' : 'Latest first';
  }
  if (sortType === 'Severity') {
    return order === 'asc' ? 'High to Low' : 'Low to High';
  }
  throw Error('Sort Type not supported');
};

const defaultColumns = [
  'title',
  'severity',
  'status',
  'inventory_model.name',
  'due_at',
  'created_at',
];

const LOCAL_STORAGE_COLUMNS_KEY = 'model-findings-columns-v1';

export default function ModelFindings() {
  const { getAccessTokenSilently } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const filtersState = searchParams.get('filters');
  const [findings, setFindings] = useState<TFinding[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);

  const { getModelFindingsColumns, updateModelFindingsColumns } =
    useUserUISettings();

  const storedColumnConfig = getModelFindingsColumns();

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    storedColumnConfig ? storedColumnConfig : defaultColumns,
  );

  const [selectedFilters, setSelectedFilters] = useState<
    FindingFilters | undefined
  >(filtersState ? JSON.parse(decodeURIComponent(filtersState)) : undefined);

  const [tableColumns, setTableColumns] = useState<any[]>([]);

  const [sortBy, setSortBy] = useState<APIRequestSortBy | undefined>(
    initialSortBy,
  );

  const { isLoading, refetch, isRefetching, data } = useQuery(
    ['findings'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetFindings(
        accessToken,
        page,
        limit,
        selectedFilters,
        sortBy,
      );
    },
    {
      onSuccess: data => {
        setFindings(data.results);
      },
      onError: err => {
        // track errors
      },
    },
  );

  const { data: allFilterOptions, isLoading: loadingFilterOptions } = useQuery(
    ['model-findings', 'filters'],
    async () => {
      const accessToken = await getAccessTokenSilently();

      return API.GetFindingFilters(accessToken);
    },
    {
      onError: err => {
        // track errors
      },
    },
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (selectedFilters) {
      setPage(1);
      refetch();

      setSearchParams(
        `?filters=${encodeURIComponent(JSON.stringify(selectedFilters))}`,
      );
    }
  }, [selectedFilters]);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    setPage(1);
    refetch();
  }, [sortBy]);

  useEffect(() => {
    const columns = [
      {
        Header: ' ',
        disableSoryBy: true,
        Cell: ({ row }: any) => {
          return (
            <Center>
              <Icon
                boxSize={6}
                color={'neutral.400'}
                as={ExclamationTriangleIcon}
              />
            </Center>
          );
        },
        sortType: null,
      },
      ...selectedColumns.map(c => ALL_COLUMNS.find(s => s.accessor === c)),
    ];
    setTableColumns(columns);
  }, [selectedColumns]);

  const onTableSort = (column: any[]) => {
    if (column.length > 0) {
      const c = ALL_COLUMNS.find(s => s.accessor === column[0].id)!;
      const order = column[0].desc ? 'desc' : 'asc';

      setSortBy({
        label: c.Header,
        field: c.sortField,
        order,
        orderLabel: getLabelFromSortType(c.sortType, order),
      });
    } else {
      setSortBy(initialSortBy);
    }
  };

  const defaultTableSortBy = [
    {
      id: sortBy?.field || initialSortBy.field,
      desc: sortBy?.order === 'desc',
    },
  ];

  const filterDisplays: any[] = [];

  // The purpose of this code is to map selectFilters, which contains list of ids
  // for each filter, into allFilterOptions, which contains the data record for
  // the filter
  if (selectedFilters && allFilterOptions) {
    Object.keys(selectedFilters).forEach((selectedFilterKey: string) => {
      const filterDef = filterDefs[selectedFilterKey];

      if (!filterDef) {
        return;
      }

      // @ts-ignore:
      const allFiltersForKey = allFilterOptions[selectedFilterKey];

      // @ts-ignore:
      selectedFilters[selectedFilterKey].forEach(selectedId => {
        const idKey = filterDef.accessors?.id;
        let label;
        let picture;

        if (idKey) {
          const filterRecord = allFiltersForKey.find(
            (a: any) => a[idKey] === selectedId,
          );
          label = filterRecord[filterDef.accessors?.label];
          picture = filterRecord[filterDef.accessors?.picture];
        } else {
          label = selectedId;
        }

        filterDisplays.push(
          <Tag
            variant={'outline'}
            colorScheme={'neutral'}
            key={selectedId}
            size={'md'}
          >
            {picture && (
              <AvatarProxy
                src={picture}
                size="xs"
                name={label}
                ml={-1}
                mr={2}
              />
            )}
            <TagLabel>
              <strong>{filterDef.tag}:</strong> {label}
            </TagLabel>
            <TagCloseButton
              onClick={() =>
                setSelectedFilters({
                  ...selectedFilters,
                  // @ts-ignore:
                  [selectedFilterKey]: selectedFilters[
                    selectedFilterKey
                  ].filter((o: any) => {
                    return o !== selectedId;
                  }),
                })
              }
            />
          </Tag>,
        );
      });
    });
  }

  return (
    <Box
      py={10}
      px={8}
      flex={1}
      w={'full'}
      overflow={'auto'}
      className="no-scrollbar"
      bg={useColorModeValue('white', 'black')}
    >
      <LoadingContainer isLoading={isLoading}>
        <Stack>
          <HStack>
            <Flex width={'full'} justify={'space-between'}>
              <HStack gap={5} pl={2} color={'inherit'}>
                <Icon as={ExclamationTriangleIcon} boxSize={10} />
                <ContentPageTitle>
                  Model Findings
                  <MoreInfoPopOver
                    title="Model Findings"
                    description="View your findings across all models."
                    link="https://docs.validmind.ai/guide/model-validation/view-filter-model-findings.html"
                    placement="right-end"
                    iconProps={{
                      ml: 2,
                    }}
                  />
                </ContentPageTitle>
              </HStack>
            </Flex>
          </HStack>

          <HStack spacing={8} align={'stretch'} justifyContent={'end'}>
            <HStack>
              <TableColumnPicker
                selectedColumnIds={selectedColumns}
                allColumns={ALL_COLUMNS.map(c => ({
                  id: c.accessor,
                  label: c.Header,
                }))}
                setColumns={newColumns => {
                  updateModelFindingsColumns(newColumns);
                  setSelectedColumns(newColumns as any);
                }}
              />
              {allFilterOptions && (
                <ModelFindingsFilters
                  selectedFilters={selectedFilters}
                  allFilterOptions={allFilterOptions}
                  setFilters={setSelectedFilters}
                />
              )}
            </HStack>
          </HStack>

          <HStack
            px={5}
            alignItems={'flex-start'}
            gap={selectedFilters && !allFiltersEmpty(selectedFilters) ? 4 : 0}
          >
            {selectedFilters && !allFiltersEmpty(selectedFilters) && (
              <HStack alignItems={'flex-start'}>
                <Flex
                  alignItems={'center'}
                  gap={2}
                  color={'neutral.500'}
                  whiteSpace={'nowrap'}
                >
                  <Icon as={FunnelIcon} width={5} height={5} />
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    Filtered by:
                  </Text>
                </Flex>

                <Flex>
                  {selectedFilters && (
                    <Wrap>
                      {selectedFilters.cuids &&
                        selectedFilters.cuids.length > 0 && (
                          <Tag
                            key={'cuid-filter-tag'}
                            size={'md'}
                            borderRadius="full"
                          >
                            <TagLabel>
                              <strong>ID Filter: </strong>{' '}
                              {selectedFilters.cuids.length} Findings
                            </TagLabel>
                            <TagCloseButton
                              onClick={() =>
                                setSelectedFilters({
                                  ...selectedFilters,
                                  cuids: undefined,
                                })
                              }
                            />
                          </Tag>
                        )}
                      {filterDisplays}
                    </Wrap>
                  )}
                </Flex>
              </HStack>
            )}

            {sortBy && (
              <HStack margin={'0 !important'}>
                <Flex
                  alignItems={'center'}
                  gap={2}
                  whiteSpace={'nowrap'}
                  color={'neutral.500'}
                >
                  <Icon as={BarsArrowDownIcon} width={5} height={5} />
                  <Text fontSize={'sm'} fontWeight={'semibold'}>
                    Sorted by:
                  </Text>

                  <Wrap>
                    {sortBy && (
                      <Tag key={sortBy.field} size={'md'}>
                        <TagLabel>
                          <strong>{sortBy.label}: </strong>
                          {sortBy.orderLabel}
                        </TagLabel>
                      </Tag>
                    )}
                  </Wrap>
                </Flex>
              </HStack>
            )}
          </HStack>

          <Stack spacing={1} pt={isRefetching || isLoading ? 0 : 3}>
            {(isRefetching || isLoading) && (
              <Progress size={'xs'} isIndeterminate colorScheme={'brand'} />
            )}

            {data?.total === 0 && (
              <Center>
                <EmptyStateDisplay variant="no-findings">
                  <Heading as={'h5'}>No findings</Heading>
                  <Text align={'center'}>
                    We couldn't find any findings with your search or filter
                    combination.
                    <br />
                    Please adjust your criteria and try again.
                  </Text>
                </EmptyStateDisplay>
              </Center>
            )}
            <Stack
              display={data && data?.total > 0 ? 'block' : 'none'}
              pt={isRefetching || isLoading ? 1 : 0}
            >
              <DataTable
                data={findings}
                columns={tableColumns}
                enableSort={true}
                pageSize={limit}
                isInteractive={true}
                onClickRow={(row: any) => {
                  navigate(
                    `/model-inventory/${row.original.inventory_model.cuid}/findings/${row.original.cuid}`,
                  );
                }}
                manualSortBy={true}
                defaultSortBy={defaultTableSortBy}
                onSort={onTableSort}
                tableProps={{}}
              />
            </Stack>
          </Stack>

          {data?.results && data.results.length > 0 && (
            <Center>
              <HStack>
                <Button
                  size={'sm'}
                  variant={'ghost'}
                  leftIcon={<Icon as={ArrowLongLeftIcon} boxSize={5} />}
                  onClick={() => setPage(page - 1)}
                  visibility={page === 1 ? 'hidden' : 'visible'}
                  disabled={page === 1}
                >
                  Prev
                </Button>
                <Text fontSize={'sm'}>
                  Showing {(page - 1) * limit + 1} to{' '}
                  {Math.min(page * limit, data.total)} of {data.total} Findings
                </Text>
                <Button
                  size={'sm'}
                  variant={'ghost'}
                  rightIcon={<Icon as={ArrowLongRightIcon} boxSize={5} />}
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  visibility={data.total / limit <= page ? 'hidden' : 'visible'}
                  disabled={data.total / limit <= page}
                >
                  Next
                </Button>
              </HStack>
            </Center>
          )}
        </Stack>
      </LoadingContainer>
      <Copyright />
      <GetStartedChecklist />
    </Box>
  );
}
