import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import ModelDocumentationOverview from './Overview';
import { useContext, useEffect, useState } from 'react';
import { TComment } from '../../api/API';
import { LoadingContainer } from '../../components/LoadingContainer';
import { Flex, useColorModeValue } from '@chakra-ui/react';

import { CommentsContext } from '../../contexts';

import GettingStarted from './GettingStarted';
import { ViewFinding } from './Findings';
import DocumentationPage from '../../components/Layout/DocumentationPage';
import { KBarProvider } from 'kbar';
import CommandPalette from '../../components/CommandPalette';
import NotFound from '../NotFound';
import LayoutContext from '../../contexts/LayoutContext';
import InventoryModelContext, {
  InventoryModelTemplates,
} from '../../contexts/InventoryModel';
import ValidationReportOverview from './Overview/ValidationReport';
import OfflineDocumentationOverview from './Overview/Offline';
import MonitoringOverview from './Overview/Monitoring';

const ProjectPage = ({ children }: any) => (
  <Flex id="project-page" width="100%">
    {children}
  </Flex>
);

const ProjectDetails = ({ isDocumentationPage, children }: any) => (
  <Flex
    position="relative"
    id="project-details"
    direction="column"
    align={isDocumentationPage ? 'stretch' : 'center'}
    justify="start"
    width={'100%'}
    gap={isDocumentationPage ? 0 : 4}
    pb={isDocumentationPage ? 0 : 12}
    bg={useColorModeValue('white', 'black')}
    overflow={'auto'}
    className="no-scrollbar"
  >
    {children}
  </Flex>
);

/**
 * Documentation and Validation Report pages require
 * a submenu to render their subpages. This submenu
 * is rendered with the SideNavSubmenu component.
 */
export const sideNavRequiresSubmenu = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  // Simple check for now, but will need to be more robust
  const requiresSubmenu =
    currentPath.includes('documentation') ||
    currentPath.includes('validation-report') ||
    currentPath.includes('monitoring');

  return requiresSubmenu;
};

/**
 * Some internal links can route to a content block URL, i.e.
 *    /model-inventory/cln579h9l01banu8hgc7bbqbk/documentation/test-description:validmind.data-validation.ClassImbalance
 *
 * In this case, we want to redirect to the parent section URL, i.e.
 *
 *   /model-inventory/cln579h9l01banu8hgc7bbqbk/documentation/data-quality
 *
 * To extract the parent section we will have to locate the content block in the
 * documentation template.
 *
 * NOTE:
 *  - This method will stop working once we allow for duplicate `content_id`s in the template
 *  - This routing logic should be moved to the backend to account for deleted blocks
 */
const getContentBlockPath = (
  inventoryModelId: string,
  templates: InventoryModelTemplates,
  path: string,
) => {
  const pathParts = path.split('/');
  const contentBlockParts = pathParts[pathParts.length - 1].replace('-', '_');

  // If contentBlockParts starts with "test-description:" then the link belongs to a text block
  // inside a test driven block. The `content_id` is the last part of the path after the colon.
  const isTextBlock =
    contentBlockParts.startsWith('test_description:') ||
    contentBlockParts.startsWith('metric_description:');

  if (!isTextBlock) {
    return '';
  }

  const isDocumentationPage = pathParts[3] === 'documentation';
  const templateKey = isDocumentationPage
    ? 'documentation'
    : 'validation_report';

  // Lower case and replace - and spaces with _ to match the content_id format
  const firstColonIndex = contentBlockParts.indexOf(':');
  const contentId = contentBlockParts.substring(firstColonIndex + 1);
  const contentIdLowerCase = contentId.toLowerCase().replace(/[- ]/g, '_');

  const templateSections = templates[templateKey].sections;
  // Look up the contentId in the template by finding a matching content_id inside the section `contents` array
  const section = templateSections.find(section =>
    section.contents?.find(
      content => content.content_id.toLowerCase() === contentIdLowerCase,
    ),
  );

  if (!section) {
    return '';
  }

  // Replace _ with - to match the URL format
  const sectionIdPath = section.id.replace(/_/g, '-');
  return `/model-inventory/${inventoryModelId}/${templateKey}/${sectionIdPath}`;
};

export default function Project() {
  const { inventoryModel, templates } = useContext(InventoryModelContext);

  const { id } = useParams();

  const currentPath = useLocation();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [comments, setComments] = useState<TComment[]>([]);
  const [rightSidebarHidden, setRightSidebarHidden] = useState<boolean>(true);
  const requiresSubmenu = sideNavRequiresSubmenu();

  useEffect(() => {
    if (inventoryModel) {
      (async () => {
        setLoading(true);
        const descriptionPath = currentPath.pathname
          .replace('/model-inventory', '')
          .replace('-', '_');

        // Redirect to overview when visiting the project base path
        if (
          currentPath.pathname.endsWith(id!) ||
          currentPath.pathname.endsWith('documentation')
        ) {
          navigate('documentation-overview', { replace: true });
          // Redirect to project summary if visiting the validation report base path
        } else if (currentPath.pathname.endsWith('validation-report')) {
          navigate('validation-report/overview', { replace: true });
          // Check if the path has test-description:
        } else if (currentPath.pathname.endsWith('monitoring')) {
          navigate('monitoring/overview', { replace: true });
        } else if (
          descriptionPath.includes('test_description:') ||
          descriptionPath.includes('metric_description:')
        ) {
          const contentBlockPath =
            templates.isReady &&
            getContentBlockPath(
              inventoryModel.cuid,
              templates,
              currentPath.pathname,
            );

          if (contentBlockPath) {
            navigate(contentBlockPath + currentPath.search + currentPath.hash, {
              replace: true,
            });
          }
        }

        setLoading(false);
      })();
    }
  }, [inventoryModel, currentPath.pathname, templates.isReady]);

  return (
    <KBarProvider
      options={{
        enableHistory: true,
      }}
    >
      <LayoutContext.Provider
        value={{
          rightSidebarHidden,
          setRightSidebarHidden,
        }}
      >
        <CommandPalette />
        <CommentsContext.Provider value={{ comments, setComments }}>
          <ProjectPage>
            <ProjectDetails isDocumentationPage={requiresSubmenu}>
              <LoadingContainer isLoading={isLoading}>
                {inventoryModel && (
                  <Routes>
                    <Route
                      path="documentation-overview"
                      element={
                        inventoryModel.template.is_offline ? (
                          <OfflineDocumentationOverview documentType="model_documentation" />
                        ) : (
                          <ModelDocumentationOverview />
                        )
                      }
                    />

                    <Route
                      path="validation-report/overview"
                      element={
                        inventoryModel.template_vr.is_offline ? (
                          <OfflineDocumentationOverview documentType="validation_report" />
                        ) : (
                          <ValidationReportOverview />
                        )
                      }
                    />

                    <Route
                      path="monitoring/overview"
                      element={
                        inventoryModel.template_monitoring.is_offline ? (
                          <OfflineDocumentationOverview documentType="monitoring" />
                        ) : (
                          <MonitoringOverview />
                        )
                      }
                    />
                    <Route
                      path="findings/:finding_cuid"
                      element={<ViewFinding />}
                    />
                    <Route
                      path="getting-started"
                      element={
                        <GettingStarted inventoryModel={inventoryModel} />
                      }
                    />

                    <Route
                      path=":documentType/:pageId"
                      element={<DocumentationPage />}
                    />
                    {/* links direct to the project's documentation or validation-report,
                        DocumentationPage should resolve and redirect to its homepage url to satisfy route above */}
                    <Route
                      path=":documentType"
                      element={<DocumentationPage />}
                    />

                    <Route path="*" element={<NotFound />} />
                  </Routes>
                )}
              </LoadingContainer>
            </ProjectDetails>
          </ProjectPage>
        </CommentsContext.Provider>
      </LayoutContext.Provider>
    </KBarProvider>
  );
}
