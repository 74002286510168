import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../../api/API';
import ReactFlow, {
  Background,
  Controls,
  Edge,
  MiniMap,
  Node,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { displayFormatedDateAndTime } from '../../utils';
import {
  edgeTypes,
  nodeTypes,
} from '../../pages/Settings/Workflows/components/WorkflowCanvas';
import FloatingConnectionLine from '../../pages/Settings/Workflows/components/edges/FloatingEdge/FloatingConnectionLine';

const initialNodes: Node[] = [];
const initialEdges: Edge[] = [];

interface Props {
  triggerId: string;
  targetCuid: string;
}
export default function WorkflowExecutionsViewer({
  triggerId,
  targetCuid,
}: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [showExecutionLogTab, setShowExecutionLogTab] = useState(false);
  const { data, isLoading } = useQuery(
    ['workflows', 'executions', triggerId, targetCuid],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetWorkflowExecutions(accessToken, triggerId, targetCuid);
    },
    {
      enabled: isOpen,
      onSuccess: data => {
        if (data.exists) {
          const nodes = data.execution.workflow.version.source.nodes.map(
            (node: Node) => {
              node.data.execution = data.execution;
              if (node.id === data.execution.current_node) {
                return {
                  ...node,
                  className: 'current-workflow-node',
                };
              }
              return node;
            },
          );
          setNodes(nodes || []);
          setEdges(data.execution.workflow.version.source.edges || []);
        }
      },
    },
  );

  const startExecution = useMutation(
    ['workflows', 'executions', triggerId, targetCuid],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.StartWorkflowExecutions(accessToken, triggerId, targetCuid);
    },
    {
      onSuccess: () => {
        window.location.reload();
      },
    },
  );

  return (
    <>
      <Button
        variant={'ghost'}
        onClick={onOpen}
        leftIcon={<Icon as={ArrowsRightLeftIcon} />}
        data-testid="see-workflow-btn"
      >
        See Workflow
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent bg="neutral.50" data-testid="workflow-modal">
          {isLoading ? (
            <Progress isIndeterminate={true} />
          ) : (
            <>
              {data?.exists ? (
                <>
                  <ModalHeader>{data.execution.workflow.title}</ModalHeader>
                  <ModalCloseButton data-testid="close-workflow-modal-btn" />

                  <ModalBody>
                    <Tabs size="md" colorScheme="brand">
                      {showExecutionLogTab && (
                        <TabList>
                          <Tab
                            onDoubleClick={() =>
                              setShowExecutionLogTab(!showExecutionLogTab)
                            }
                          >
                            Workflow
                          </Tab>
                          <Tab>Execution Log</Tab>
                        </TabList>
                      )}

                      <TabPanels>
                        <TabPanel>
                          <Box h={'600px'} w={'full'}>
                            <ReactFlow
                              nodes={nodes}
                              edges={edges}
                              nodeTypes={nodeTypes}
                              edgeTypes={edgeTypes}
                              connectionLineComponent={FloatingConnectionLine}
                              fitView
                            >
                              <Background />
                              <MiniMap />
                              <Controls />
                            </ReactFlow>
                          </Box>
                        </TabPanel>
                        <TabPanel>
                          <Accordion allowToggle>
                            {data.execution.steps.map((step, index) => (
                              <AccordionItem>
                                <h2>
                                  <AccordionButton>
                                    <Box as="span" flex="1" textAlign="left">
                                      {displayFormatedDateAndTime(
                                        step.created_at,
                                      )}{' '}
                                      - {step.ran_by?.name || 'user'} triggered{' '}
                                      {step.execution_data.trigger} -{' '}
                                      {step.tags.join(', ')}
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <pre>
                                    {JSON.stringify(
                                      step.execution_data,
                                      null,
                                      2,
                                    )}
                                  </pre>
                                </AccordionPanel>
                              </AccordionItem>
                            ))}
                          </Accordion>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </ModalBody>
                </>
              ) : (
                <ModalBody>
                  <Text>There are no executions for this workflow</Text>
                  <Button
                    onClick={() => startExecution.mutate()}
                    isLoading={startExecution.isLoading}
                  >
                    Start execution for {triggerId}
                  </Button>
                </ModalBody>
              )}
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
