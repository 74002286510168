import {
  Box,
  Button,
  Center,
  Heading,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { LoadingContainer } from '../../../components/LoadingContainer';
import { useContext, useEffect, useState } from 'react';
import SidebarContext from '../../../contexts/SidebarContext';
import { PlusIcon, PuzzlePieceIcon } from '@heroicons/react/24/outline';
import { DataTable } from '../../../components/DataTable';
import useModelSchema from '../../../hooks/useModelSchema';
import CustomFieldModal from '../../../components/CustomFieldModal';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { EmptyStateDisplay } from '../../../components/EmptyStateDisplay';
import { ContentPageTitle } from '../../../components/Layout';
import MoreInfoPopOver from '../../../components/MoreInfoPopOver';
import ExpandableTextDisplay from '../../../components/ExpandableTextDisplay';
import {
  schemaTypesConfig,
  TypeConfig,
} from '../../../components/CustomFieldModal/schema_types';
import { PencilIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { UsersContext } from '../../../contexts';
import { useNavigate } from 'react-router-dom';

const CustomFields = React.memo(() => {
  const { userHasPermission } = useContext(UsersContext);
  const { setInSettings } = useContext(SidebarContext);
  const navigate = useNavigate();

  const canReadInventorySchema = userHasPermission(
    ['read_inventory_schema'],
    'all',
  );
  const canUpdateInventorySchema = userHasPermission(
    ['update_inventory_schema'],
    'all',
  );

  const {
    data: modelSchema,
    propertyItems: properties,
    isLoading,
    refetch,
  } = useModelSchema();

  useEffect(() => {
    if (!canReadInventorySchema) {
      navigate('/settings');
      return;
    }

    refetch();
    setInSettings(true);
    return () => {
      setInSettings(false);
    };
  }, [canReadInventorySchema]);

  // Tracks which key is open in the edit modal
  const [editKeyModalOpen, setEditKeyModalOpen] = useState('');

  const tableColumns = [
    {
      Header: ' ',
      Cell: ({ row }: any) => {
        return <Icon boxSize={6} color={'neutral.400'} as={PuzzlePieceIcon} />;
      },
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ row }: any) => {
        return (
          <Text fontWeight={'bold'} fontSize={'sm'}>
            {row.values.title}
          </Text>
        );
      },
    },
    {
      Header: 'Key',
      accessor: 'key',
      Cell: ({ row }: any) => {
        return (
          <Text fontSize={'sm'}>
            <pre>{row.values.key}</pre>
          </Text>
        );
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ row }: any) => {
        return (
          <Text fontSize={'sm'}>
            {
              schemaTypesConfig.find(
                (t: TypeConfig) => t.typeId === row.original.typeId,
              )?.name
            }
          </Text>
        );
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ row }: any) => {
        return (
          <ExpandableTextDisplay
            value={row.original.description}
            maxLines={2}
            fontSize={'sm'}
          />
        );
      },
    },
    {
      Header: 'Required',
      accessor: 'requiredOnRegistration',
      Cell: ({ row }: any) => {
        return (
          <Text fontSize={'sm'}>
            {row.values.requiredOnRegistration ? 'Yes' : ''}
          </Text>
        );
      },
    },
  ];

  if (canUpdateInventorySchema) {
    tableColumns.push({
      Header: 'Actions',
      Cell: ({ row }: any) => {
        return (
          <Box>
            <Button
              onClick={() => setEditKeyModalOpen(row.original.key)}
              variant={'ghost'}
              leftIcon={<Icon as={PencilIcon} boxSize={3} />}
            >
              Edit
            </Button>
            <CustomFieldModal
              schema={modelSchema!}
              onSave={onSave}
              editKey={row.original.key}
              isOpen={editKeyModalOpen === row.original.key}
              onCustomFieldModalClose={() => setEditKeyModalOpen('')}
            />
          </Box>
        );
      },
    });
  }

  const onSave = () => {
    refetch();
  };

  return (
    <LoadingContainer isLoading={isLoading}>
      <VStack
        alignItems="start"
        spacing={0}
        paddingTop={12}
        mt={1}
        paddingBottom={16}
        px={14}
        gap={8}
        w="full"
        overflow="auto"
        className="no-scrollbar"
        data-testid="groups-settings"
        maxWidth={'7xl'}
        mx={'auto'}
      >
        <Box>
          <Breadcrumbs />
          <ContentPageTitle>
            Model Inventory Fields
            <MoreInfoPopOver
              title="Manage model inventory fields"
              link="https://docs.validmind.ai/guide/model-inventory/manage-inventory-custom-fields.html"
              placement="right-end"
              iconProps={{
                ml: 2,
              }}
            />
          </ContentPageTitle>
        </Box>
        <Text fontSize="md">
          These fields will be added to every model in the inventory.
        </Text>
        <VStack w={'full'} alignItems={'flex-end'}>
          {canUpdateInventorySchema && (
            <Button
              onClick={() => setEditKeyModalOpen('add-new-field')}
              variant={'ghost'}
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
            >
              Add New Field
            </Button>
          )}
          <CustomFieldModal
            schema={modelSchema!}
            onSave={onSave}
            isOpen={
              editKeyModalOpen === 'add-new-field' && canUpdateInventorySchema
            }
            onCustomFieldModalClose={() => setEditKeyModalOpen('')}
          />
          {properties.length > 0 && (
            <DataTable
              data={properties}
              columns={tableColumns}
              enableSort={true}
            />
          )}
        </VStack>
        {!isLoading && properties.length === 0 && (
          <Center w={'full'}>
            <EmptyStateDisplay variant="no-workflow">
              <Heading as="h5">
                No model inventory fields have been defined
              </Heading>
              <Text align="center" pb={5}>
                All inventory models are using the default fields.
              </Text>

              {canUpdateInventorySchema && (
                <Button
                  onClick={() => setEditKeyModalOpen('add-new-field')}
                  variant={'ghost'}
                  leftIcon={<Icon as={PlusIcon} boxSize={5} />}
                >
                  Add New Field
                </Button>
              )}
              <CustomFieldModal
                schema={modelSchema!}
                onSave={onSave}
                isOpen={
                  editKeyModalOpen === 'add-new-field' &&
                  canUpdateInventorySchema
                }
                onCustomFieldModalClose={() => setEditKeyModalOpen('')}
              />
            </EmptyStateDisplay>
          </Center>
        )}
      </VStack>
    </LoadingContainer>
  );
});

export default CustomFields;
