import * as React from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import {
  Box,
  Divider,
  HStack,
  Icon,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { TemplateSectionTree } from '../../../../models/template';
import { SmartLink } from '../../../SmartLink';
import InventoryModelContext from '../../../../contexts/InventoryModel';
import { TInventoryModel } from '../../../../models/inventory_model';

interface IMenuItemProps {
  menuItem: IMenuItem;
}

interface IMenuGroupProps {
  menuItem: IMenuItem;
}

export interface IMenuItem {
  id?: string;
  index?: string;
  label: string;
  path?: string;
  condensed?: boolean;
  indexOnly?: boolean;
  icon?: React.ReactNode;
  children?: IMenuItem[];
}

export const menuItemLabel = (menuItem: IMenuItem) => {
  return menuItem.index
    ? `${menuItem.index}. ${menuItem.label}`
    : menuItem.label;
};

/**
 * When rendering the menu items that include the condensed version of the
 * documentation, we don't need to list the child pages of that condensed page.
 * We use this when rendering the default table of contents menu
 *
 * When includeCondensed is false, we return the full menu. This is useful for
 * building the cmd-k actions menu
 */
const getChildSections = (
  inventoryModel: TInventoryModel,
  documentType: string,
  section: TemplateSectionTree,
) => {
  const childSections =
    section.sections &&
    section.sections.length > 0 &&
    getProjectMenuItemsFromTemplate(
      inventoryModel,
      documentType,
      section.sections,
      section,
    );

  return childSections;
};

export const getProjectMenuItemsFromTemplate = (
  inventoryModel: TInventoryModel,
  documentType: string,
  sections: TemplateSectionTree[],
  parentSection: TemplateSectionTree | null = null,
): IMenuItem[] =>
  sections.map(section => {
    const baseUrl = `/model-inventory/${inventoryModel.cuid}`;
    return {
      id: section.id,
      label: section.title,
      path: `${baseUrl}/${documentType}/${
        parentSection && parentSection.condensed
          ? // e.g. model-overview#h-sub-section
            `${parentSection.id.replace(/_/g, '-')}#h-${section.id.replace(
              /_/g,
              '-',
            )}`
          : section.id.replace(/_/g, '-')
      }`,
      index: section.index,
      condensed: section.condensed,
      indexOnly: section.index_only,
      children: getChildSections(inventoryModel, documentType, section),
    } as IMenuItem;
  });

const MenuItem = ({ menuItem }: IMenuItemProps) => {
  const { label, path } = menuItem;
  const location = useLocation();

  let isCurrentPage = false;
  const currentPath = location.pathname.concat(location.hash);
  if (currentPath.includes(path!)) {
    isCurrentPage = true;
  }

  return (
    <HashLink to={path!}>
      <HStack
        pl={3}
        pr={2}
        py={2}
        spacing={1}
        borderRadius={'sm'}
        alignItems={'flex-start'}
        // fontWeight={'semibold'}
        data-testid={`${label}-MenuItem`}
        transition={'all 0.3s ease-in-out'}
        bg={
          isCurrentPage
            ? useColorModeValue('transparent', 'brand.1000')
            : 'transparent'
        }
        borderLeft={'4px solid'}
        borderColor={isCurrentPage ? 'brand.base' : 'transparent'}
        color={
          isCurrentPage
            ? useColorModeValue('brand.600', 'brand.base')
            : useColorModeValue('neutral.700', 'neutral.300')
        }
        _hover={
          isCurrentPage
            ? { bg: useColorModeValue('brandSecondary.25', 'brand.950') }
            : {
                bg: useColorModeValue(
                  'brandSecondary.25',
                  'brandSecondary.950',
                ),
                color: useColorModeValue('neutral.700', 'brandSecondary.50'),
              }
        }
      >
        <Text
          pt={'2px'}
          fontSize={'sm'}
          fontFamily={'monospace'}
          letterSpacing={'-2px'}
          pr={1}
        >{`${menuItem.index}.`}</Text>
        <Text>{menuItem.label}</Text>
      </HStack>
    </HashLink>
  );
};

// Go through the two levels of children to get all child paths
const getChildPaths = (group: IMenuItem): string[] => {
  let childPaths: string[] = [];

  if (group.children) {
    group.children.forEach(child => {
      if (child.path) {
        childPaths.push(child.path);
      }
      childPaths = childPaths.concat(getChildPaths(child));
    });
  }

  return childPaths;
};

// Checks if the current path is a child of the group
const isGroupOpen = (group: IMenuItem) => {
  const childPaths = getChildPaths(group);

  if (!childPaths.length) {
    return false;
  }
  const location = useLocation();
  const currentPath = location.pathname + location.hash;

  return childPaths.some(childPath => currentPath.includes(childPath));
};

const MenuGroup = ({ menuItem }: IMenuGroupProps) => {
  if (menuItem.children) {
    const c = menuItem.children.map((child, id) => {
      return <MenuGroup menuItem={child} key={id} />;
    });

    return (
      <details
        key={`parent-${menuItemLabel(menuItem)}`}
        open={isGroupOpen(menuItem)}
      >
        <Box
          p={2}
          w={'full'}
          as="summary"
          display={'flex'}
          color={'neutral.300'}
          cursor={'pointer'}
          borderRadius={'sm'}
          alignItems={'flex-start'}
          _hover={{
            bg: useColorModeValue('brandSecondary.25', 'brandSecondary.950'),
          }}
          role="group"
        >
          {/* If a path is assigned to this group, render it as a link */}
          {menuItem.path && !menuItem.indexOnly ? (
            <HashLink to={menuItem.path}>
              <HStack
                spacing={1}
                borderRadius={'sm'}
                color={useColorModeValue('neutral.700', 'neutral.300')}
                alignItems={'flex-start'}
                _hover={{
                  bg: useColorModeValue(
                    'brandSecondary.25',
                    'brandSecondary.950',
                  ),
                }}
                transition={'all 0.3s ease-in-out'}
                data-testid={`${menuItem.label}-MenuGroup`}
              >
                <Text
                  pt={'2px'}
                  fontSize={'sm'}
                  fontFamily={'monospace'}
                  letterSpacing={'-2px'}
                >
                  {`${menuItem.index}.`}
                </Text>
                <Text>{menuItem.label}</Text>
              </HStack>
            </HashLink>
          ) : (
            <HStack
              spacing={1}
              borderRadius={'sm'}
              color={useColorModeValue('neutral.700', 'brandSecondary.50')}
              _groupHover={{
                color: useColorModeValue('neutral.700', 'brandSecondary.50'),
              }}
              alignItems={'flex-start'}
              transition={'all 0.3s ease-in-out'}
              data-testid={`${menuItem.label}-MenuGroup`}
            >
              <Text
                pt={'2px'}
                fontSize={'sm'}
                fontFamily={'monospace'}
                letterSpacing={'-2px'}
              >
                {`${menuItem.index}.`}
              </Text>
              <Text>{menuItem.label}</Text>
            </HStack>
          )}
          <Box ml={'auto'}>
            <Icon as={ChevronDownIcon} boxSize={4} />
          </Box>
        </Box>
        <Box
          ml={2.5}
          // pl={1.5}
          borderLeft={'1px solid'}
          borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        >
          <nav>{c}</nav>
        </Box>
      </details>
    );
  } else {
    return <MenuItem menuItem={menuItem} key={`child-${menuItem.label}`} />;
  }
};

export default function DocumentationPageSidebar() {
  const location = useLocation();
  const { inventoryModel, templates } = useContext(InventoryModelContext);
  const currentPath = location.pathname;

  let menuItems: IMenuItem[] = [];

  if (inventoryModel && templates.isReady) {
    menuItems = getProjectMenuItemsFromTemplate(
      inventoryModel,
      templates.current.documentType.replace(/_/g, '-'),
      templates.current.content.sectionTree,
    );
  }

  let baseUrl = `/model-inventory/${inventoryModel!.cuid}`;
  let smartLinkTo = `${baseUrl}/documentation-overview`;
  let smartLinkName = 'Document Overview';

  if (currentPath.includes('/validation-report/')) {
    smartLinkTo = `${baseUrl}/validation-report/overview`;
    smartLinkName = 'Validation Report Overview';
  } else if (currentPath.includes('/monitoring/')) {
    smartLinkTo = `${baseUrl}/monitoring/overview`;
    smartLinkName = 'Ongoing Monitoring Overview';
  }

  return (
    <VStack id="sidenav-submenu" gap={4} w="full">
      {/* {!currentPath.includes('/validation-report') && ( */}
      <>
        <Box
          bg={
            currentPath.endsWith('/documentation-overview')
              ? useColorModeValue('neutral.100', 'brandSecondary.950')
              : ''
          }
          _hover={{
            bg: useColorModeValue('brandSecondary.25', 'brandSecondary.950'),
          }}
          w={'full'}
          alignSelf={'flex-start'}
          rounded={'sm'}
        >
          <SmartLink
            data-testid={`MenuItem-document-overview`}
            to={smartLinkTo}
          >
            <Text
              py={2}
              px={2}
              fontWeight={'semibold'}
              color={
                useColorModeValue('neutral.600', 'neutral.300')
                // currentPath.endsWith('/project-overview') ||
                // currentPath.includes('/validation-report/')
                //   ? useColorModeValue('brand.600', 'brand.base')
                //   : useColorModeValue('neutral.500', 'neutral.500')
              }
            >
              {smartLinkName}
            </Text>
          </SmartLink>
        </Box>

        <Divider />
      </>
      {/* )} */}
      <VStack alignItems={'flex-start'} width={'full'} h={'full'} gap={1}>
        <Text
          id="sidenav-submenu-title"
          px={2}
          fontWeight="semibold"
          color={'neutral.500'}
        >
          {(currentPath.includes('/documentation/') ||
            currentPath.endsWith('/documentation-overview')) &&
            'Document Outline'}
          {currentPath.includes('/validation-report') &&
            'Validation Report Outline'}
          {currentPath.includes('/monitoring') && 'Ongoing Monitoring Outline'}
        </Text>

        <Box id="sidenav-submenu-items" w={'full'} pb={2} overflow={'hidden'}>
          {menuItems.map((menuItem, idx) => (
            <MenuGroup menuItem={menuItem} key={idx} />
          ))}
        </Box>
      </VStack>
    </VStack>
  );
}
