import React, { MouseEventHandler, useEffect } from 'react';
import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Divider,
  Heading,
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import {
  ArrowRightIcon,
  ArrowUpRightIcon,
  BookOpenIcon,
  CheckCircleIcon,
  CodeBracketIcon,
  CubeTransparentIcon,
  ListBulletIcon,
  PaperAirplaneIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../../api/API';
import { APIRequestSortBy } from '../../models/utils';
import { useUserUISettings } from '../../hooks/useUserUISettings';

interface ChecklistItemProps extends BoxProps {
  body: string;
  title: string;
  actionLabel?: string;
  isComplete?: boolean;
  isExternal?: boolean;
  action?: MouseEventHandler;
  icon: React.ElementType;
}
const ChecklistItem = ({
  isComplete = false,
  isExternal = false,
  title,
  body,
  icon,
  actionLabel,
  action = () => {},
  ...rest
}: ChecklistItemProps) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Box
      {...rest}
      p={4}
      role="group"
      _hover={{ bg: useColorModeValue('neutral.100', 'neutral.850') }}
      transition={'all 0.5s ease-in-out'}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <HStack justifyContent={'space-between'} alignItems={'flex-end'}>
        <HStack spacing={6} alignItems={'flex-start'}>
          <Icon
            boxSize={7}
            as={isComplete ? CheckCircleIcon : icon}
            color={
              isComplete
                ? 'teal.300'
                : useColorModeValue('neutral.500', 'neutral.300')
            }
          />
          <VStack
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            w={'20rem'}
          >
            <Heading
              as={'h5'}
              mt={1.5}
              color={
                isComplete
                  ? useColorModeValue('neutral.400', 'neutral.500')
                  : useColorModeValue('neutral.800', 'neutral.400')
              }
              textDecoration={
                isComplete && !isHovered ? 'line-through' : 'none'
              }
            >
              {title}
            </Heading>
            <Collapse
              in={isHovered}
              transition={{
                enter: { type: 'spring', duration: 0.5, delay: 0.25 },
                exit: { duration: 0.1 },
              }}
            >
              <Box mt={2}>
                <Text color={useColorModeValue('neutral.700', 'neutral.300')}>
                  {body}
                </Text>
              </Box>
            </Collapse>
          </VStack>
        </HStack>

        {(isComplete && !isHovered) || actionLabel === undefined ? (
          <Button
            disabled={true}
            bg={isHovered && !isComplete ? 'brand.700' : 'neutral.100'}
            color={isHovered && !isComplete ? 'white' : 'neutral.800'}
            _hover={!isComplete ? { bg: 'brand.700' } : { bg: 'neutral.200' }}
            transition={'all 0.3s ease-in-out'}
          >
            Done
          </Button>
        ) : (
          <></>
        )}
        {!isComplete || (isHovered && actionLabel !== undefined) ? (
          <Button
            onClick={action}
            bg={isHovered && !isComplete ? 'neutral.100' : 'neutral.200'}
            color={isHovered && !isComplete ? 'neutral.800' : 'neutral.800'}
            _hover={{ bg: useColorModeValue('neutral.200', 'neutral.200') }}
            transition={'all 0.3s ease-in-out'}
            data-test-id={`get-started-checklist-${actionLabel
              ?.split(' ')
              .join('-')
              .toLocaleLowerCase()}`}
          >
            {actionLabel}
            <Icon
              as={isExternal ? ArrowUpRightIcon : ArrowRightIcon}
              ml={2}
              w={5}
              h={5}
            />
          </Button>
        ) : (
          <></>
        )}
      </HStack>
    </Box>
  );
};
const ProgressSegment = ({ isComplete = false }: { isComplete?: boolean }) => (
  <Box
    w={'full'}
    h={2}
    bg={isComplete ? 'teal.300' : 'neutral.500'}
    rounded={'full'}
    transition={'all 0.5s ease-in-out'}
  />
);

export default function GetStartedChecklist() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const { user, getOnboardingSetting, setOnboardingSetting, resetOnboarding } =
    useUserUISettings();

  useEffect(() => {
    if (user && !user.ui_settings.onboarding?.getAccount) {
      resetOnboarding();
    }
  }, [user]);

  const { data: latestInventoryModelCuid } = useQuery(
    ['project', 'latest'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const sortBy: APIRequestSortBy = {
        label: '',
        field: 'created_at',
        order: 'desc',
      };
      const results = await API.GetProjects(
        accessToken,
        undefined,
        undefined,
        1,
        1,
        sortBy,
      );
      if (results.results.length > 0) {
        return results.results[0].inventory_model.cuid;
      }
    },
    {
      enabled: isOpen,
    },
  );

  const getAttrList = () => {
    return [
      getOnboardingSetting('getAccount'),
      getOnboardingSetting('registerModel'),
      getOnboardingSetting('startDocumentation'),
      getOnboardingSetting('collaborateDocumentation'),
      getOnboardingSetting('submitDocumentation'),
      getOnboardingSetting('findResources'),
    ];
  };

  const toggleGuide = (newState: any) => {
    setIsOpen(newState);
  };

  const steps = getAttrList();
  const stepsDoneCount = filter(steps, Boolean).length;
  const stepsCount = steps.length;

  return (
    <Box
      position={'absolute'}
      top={0}
      left={0}
      w={'full'}
      h={'full'}
      pointerEvents={'none'}
      display={getOnboardingSetting('isDismissed') ? 'none' : 'block'}
    >
      <Box position={'absolute'} bottom={4} right={4} pointerEvents={'all'}>
        <Popover
          onOpen={() => toggleGuide(true)}
          onClose={() => toggleGuide(false)}
          defaultIsOpen={isOpen}
          closeOnBlur={false}
        >
          <PopoverTrigger>
            <Button
              py={6}
              size={'md'}
              variant={'outline'}
              rounded={'full'}
              shadow={'lg'}
              data-test-id={'get-started-checklist-open'}
            >
              <Icon as={ListBulletIcon} w={6} h={6} mr={2} /> {stepsDoneCount}/
              {stepsCount} Done
            </Button>
          </PopoverTrigger>
          <PopoverContent
            width={'36rem'}
            borderRadius={'xl'}
            overflow={'hidden'}
            boxShadow={'2xl'}
            position={'absolute'}
            bottom={6}
            right={-16}
          >
            <PopoverHeader
              fontWeight="bold"
              fontSize={'lg'}
              bg={useColorModeValue('neutral.800', 'neutral.300')}
              color={useColorModeValue('neutral.50', 'neutral.300')}
              p={4}
            >
              <Stack spacing={2}>
                <Text>Hi {user?.name}, follow this guide to get started.</Text>
                <HStack spacing={2}>
                  <ProgressSegment
                    isComplete={getOnboardingSetting('getAccount')}
                  />
                  <ProgressSegment
                    isComplete={getOnboardingSetting('registerModel')}
                  />
                  <ProgressSegment
                    isComplete={getOnboardingSetting('startDocumentation')}
                  />
                  <ProgressSegment
                    isComplete={getOnboardingSetting(
                      'collaborateDocumentation',
                    )}
                  />
                  <ProgressSegment
                    isComplete={getOnboardingSetting('submitDocumentation')}
                  />
                  <ProgressSegment
                    isComplete={getOnboardingSetting('findResources')}
                  />
                  <Box pl={4} fontSize={'md'}>
                    {stepsDoneCount}/{stepsCount}
                  </Box>
                </HStack>
              </Stack>
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton
              color={'neutral.500'}
              size={'lg'}
              mt={1}
              mr={0}
              data-test-id={'get-started-checklist-close'}
            />
            <PopoverBody p={0}>
              <Stack
                divider={
                  <Divider
                    my={'0 !important'}
                    borderColor={useColorModeValue(
                      'neutral.300',
                      'neutral.800',
                    )}
                  />
                }
              >
                <ChecklistItem
                  title={'Get your ValidMind account'}
                  body={'Sign up for a ValidMind account to get started.'}
                  isComplete={getOnboardingSetting('getAccount')}
                  icon={UserIcon}
                />
                <ChecklistItem
                  title={'Register your model'}
                  body={
                    'Click on “Register a New Model” in the Model Inventory.'
                  }
                  actionLabel="Model Inventory"
                  icon={CubeTransparentIcon}
                  isComplete={getOnboardingSetting('registerModel')}
                  action={() => {
                    setOnboardingSetting('registerModel', true);
                    navigate('/model-inventory?register=open');
                  }}
                />
                <ChecklistItem
                  title={'Start on your model documentation'}
                  body={
                    'Follow the instructions on “Getting Started” to upload results.'
                  }
                  actionLabel="Getting Started"
                  isComplete={getOnboardingSetting('startDocumentation')}
                  icon={CodeBracketIcon}
                  action={() => {
                    setOnboardingSetting('startDocumentation', true);
                    // Navigate to GET STARTED page of the latest project created
                    navigate(
                      `/model-inventory/${latestInventoryModelCuid}/getting-started`,
                    );
                  }}
                />
                <ChecklistItem
                  title={'Collaborate on your documentation'}
                  body={'Edit or comment on your model documentation.'}
                  actionLabel="Documentation"
                  isComplete={getOnboardingSetting('collaborateDocumentation')}
                  icon={UsersIcon}
                  action={() => {
                    setOnboardingSetting('collaborateDocumentation', true);
                    // Navigate to the documentation (Model Overview) page of the latest project created
                    navigate(
                      `/model-inventory/${latestInventoryModelCuid}/documentation/model-overview`,
                    );
                  }}
                />
                <ChecklistItem
                  title={'Submit your model documentation'}
                  body={
                    'Mark your project as “Ready for Validation” to submit documentation.'
                  }
                  actionLabel="Project Overview"
                  isComplete={getOnboardingSetting('submitDocumentation')}
                  icon={PaperAirplaneIcon}
                  action={() => {
                    setOnboardingSetting('submitDocumentation', true);
                    // Navigate to the project overview page of the latest project created
                    navigate(
                      `/model-inventory/${latestInventoryModelCuid}/documentation-overview`,
                    );
                  }}
                />
                <ChecklistItem
                  title={'Find guides & resources'}
                  body={
                    'Still need help? See our documentation for more resources.'
                  }
                  isComplete={getOnboardingSetting('findResources')}
                  actionLabel="Resources"
                  icon={BookOpenIcon}
                  isExternal
                  action={() => {
                    setOnboardingSetting('findResources', true);
                    window.open('https://docs.validmind.ai', '_blank');
                  }}
                />
              </Stack>
            </PopoverBody>
            <PopoverFooter px={4}>
              <HStack spacing={0}>
                <Button
                  variant={'link'}
                  color={'neutral.500'}
                  onClick={() => setOnboardingSetting('isDismissed', true)}
                  data-test-id={'get-started-checklist-dismiss'}
                >
                  Dismiss this guide
                </Button>
                <Text fontSize={'sm'} color={'neutral.600'}>
                  , you can always bring it back from your
                </Text>
                <Button
                  variant={'link'}
                  color={'neutral.500'}
                  onClick={() => navigate('/settings/profile')}
                  data-test-id={'get-started-checklist-profile-link'}
                  pl={1}
                >
                  profile settings.
                </Button>
              </HStack>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </Box>
    </Box>
  );
}
