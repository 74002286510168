import { createContext } from 'react';
import { Workflow } from '../models/workflow';
import { UseMutationResult } from 'react-query';
import { Node } from 'reactflow';

type WorkflowContext = {
  workflow?: Workflow;
  saveSource?: UseMutationResult<Workflow, unknown, any, unknown>;
  selectedNodeId?: string;
  setSelectedNodeId?: (nodeId?: string) => void;
  forceUpdateKey?: number;
  setForceUpdateKey?: (key: number) => void;
};

const WorkflowContext = createContext<WorkflowContext>({});

export default WorkflowContext;
